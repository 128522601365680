import axios from "axios";
import { cleanEmpty } from "./general";

const walletBackOfficeURL = process.env.REACT_APP_API_URL;
const WebsiteName = process.env.REACT_APP_WebsiteName;
const currentDomain = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
export const getPageBuilder = async () => {
  return await axios
    .get(`${walletBackOfficeURL}/v1/company-management/get-page-builder-config`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPageBuilderByCompany = async (name) => {
  return await axios
    .get(`${walletBackOfficeURL}/v1/company-management/public-page/${name}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const setPageBuilder = async (data) => {
  return await axios
    .post(
      `${walletBackOfficeURL}/v1/company-management/set-page-builder-config`,
      data
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const publishPublicPage = async (data) => {
  return await axios
    .post(
      `${walletBackOfficeURL}/v1/company-management/publish-public-page`,
      data
    )
    .then((res) => res)
    .catch((err) => err.response);
};

// export const getProductsList = async ({ product_type_id, item_id,no_service_type }) => {
// 	return await axios
// 		.post(`${walletBackOfficeURL}/trip-management/products_list`, {
// 			version: "2",
// 			product_type_id,
// 			item_id,
// 			no_service_type
// 		})
// 		.then((res) => res)
// 		.catch((err) => err.response);
// };
export const getProductsList = async ({
  product_type_id,
  item_id,
  no_service_type,
}) => {
  return await axios
    .post(`${walletBackOfficeURL}/trip-management/list-products-for-public`, {
      version: "2",
      product_type_id,
      item_id,
      no_service_type,
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const getPackage = async (product_type_id) => {
  return await axios
    .get(
      `${walletBackOfficeURL}/v1/company-management/packages/${product_type_id}`
    )
    .then((res) => res)
    .catch((err) => err.response);
};

export const sendRequestPackage = async (name, data) => {
  return await axios
    .post(
      `${walletBackOfficeURL}/v1/company-management/public-page/${name}/request-package`,
      data
    )
    .then((res) => res)
    .catch((err) => err.response);
};
export const sendGetInTouch = async (name, data) => {
  return await axios
    .post(
      `${walletBackOfficeURL}/v1/company-management/public-page/${name}/get-in-touch`,
      data
    )
    .then((res) => res)
    .catch((err) => err.response);
};
// filght

//Search api
//motravelg
export const fetchFlightsBuilder = async (data, page = 1) => {
  return await axios
    .post(
      `${walletBackOfficeURL}/v1/flights/page-builder/${WebsiteName}/search?page=${page}`,
      data
    )
    .then((res) => res)
    .catch((err) => err.response);
};


export const FlightCheckAvailabilityBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/flights/page-builder/${WebsiteName}/check-availability`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchFlightDetailsBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/flights/page-builder/${WebsiteName}/view-details`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const bookFlightBuilder = async (data) => {
  axios.defaults.headers.common["X-Payment-Redirect-URL"] = currentDomain;
	return await axios
		.post(`${walletBackOfficeURL}/v1/flights/page-builder/${WebsiteName}/book`, data)
		.then((res) => res)
		.catch((err) => err.response);
};



export const fetchTransferBuilder = async (data, page = 1) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/transfers/page-builder/${WebsiteName}/search?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const showTransferCartBuilder = async (cacheKey) => {
	return await axios
		.get(`${walletBackOfficeURL}/v1/transfers/page-builder/${WebsiteName}/show-cart`,{
			params: (cacheKey),
		})
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteFromTransferCartBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/transfers/page-builder/${WebsiteName}/remove-from-cart`,  data )
		.then((res) => res)
		.catch((err) => err.response);
};

export const addToTransferCartBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/transfers/page-builder/${WebsiteName}/add-to-cart`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const transferBookingBuilder = async (data) => {
  axios.defaults.headers.common["X-Payment-Redirect-URL"] = currentDomain;
	return await axios
		.post(`${walletBackOfficeURL}/v1/transfers/page-builder/${WebsiteName}/book`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchHotelsBuilder = async (data, page = 1) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/hotels/page-builder/${WebsiteName}/search?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const searchHotelFiltersBuilder = async (filters) => {
	return await axios.post(
		`${walletBackOfficeURL}/v1/hotels/page-builder/${WebsiteName}/filters`,
		filters
	);
};
export const fetchHotelBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/hotels/page-builder/${WebsiteName}/details`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const checkHotelAvailabilityBuilder = async (data) => {
	return await axios
		.post(`${walletBackOfficeURL}/v1/hotels/page-builder/${WebsiteName}/availability`, data)
		.catch((err) => err.response);
};

export const bookHotelBuilder = async (data) => {
  axios.defaults.headers.common["X-Payment-Redirect-URL"] = currentDomain;
	return await axios
		.post(`${walletBackOfficeURL}/v1/hotels/page-builder/${WebsiteName}/book`, data)
		.catch((err) => err.response);
};

export const paymentActionDone = async (param, tap) => {
  return await axios
    .post(`${walletBackOfficeURL}/online-payment/webhook/${tap}`, cleanEmpty(param))
    .then((res) => res.data)
    .catch((err) => err.response)
}