import HotelPlaceHolder from "assets/images/new-hotels.svg";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import NumberField from "components/Form/NumberField/NumberField";
import SelectField from "components/Form/SelectField/SelectField";
import { useSBSState } from "context/global";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { store } from "react-notifications-component";
import { fetchAllCurrencies } from "services/lookups";
import {
  fetchCitiesHotelsSearch,
  fetchHotels,
  fetchHotelsOffers,
  guestHotels,
  guestHotelsOffers,
} from "services/marketplace";
import Locale from "translations";
// import payloadRooms from "./rooms";
import { useParams } from "react-router-dom";
import { fetchHotelsBuilder } from "services/webBuilder";

function SearchBar({ setnight, setpage, page }) {
  const { marketPlace, messages } = Locale;
  const [county] = useFetchCountries();
  const { status } = useParams();

  const dispatch = useMarketplaceDispatch();
  const { filters, hotelFilters } = useMarketplaceState();
  const { locale, token_data } = useSBSState();
  const [showRoom, setshowRoom] = useState(false);
  const [hotel, setHotel] = useState({
    checkIn: moment(new Date()).add(1, "d"),
    checkOut: moment(new Date()).add(2, "d"),
    goingTo: null,
    adult: 1,
    child: 0,
    roomType: null,
    rooms: [{ number_of_adult: 0, BoxChild: false }],
  });

  useEffect(() => {
    // const parsedArr = (rooms = [], index = 0, result = []) => {
    // 	const room = rooms[index];
    // 	const roomIndex = result.findIndex(
    // 		(r) => r.number_of_adult === room.number_of_adult
    // 	);

    // 	if (roomIndex !== -1) {
    // 		const existingRoom = result[roomIndex];
    // 		const handeledRoom = {
    // 			...existingRoom,
    // 			roomLength: existingRoom.roomLength + 1,
    // 			roomsList: [...existingRoom.roomsList, { children: room.children }],
    // 		};

    // 		result[roomIndex] = handeledRoom;
    // 	} else {
    // 		const childrenArr = room.children.map((i) => ({ age: "" }));
    // 		const handeledRoom = {
    // 			number_of_adult: room.number_of_adult,
    // 			roomLength: 1,
    // 			roomsList: [{ children: room.children }],
    // 			children: childrenArr,
    // 		};

    // 		result.push(handeledRoom);
    // 	}

    // 	const roomLength = rooms.length;
    // 	if (roomLength > 0 && roomLength - 1 > index) {
    // 		return parsedArr(payloadRooms, index + 1, result);
    // 	}

    // 	return result;
    // };

    if (token_data) {
      let JSON_search_payload = token_data.search_payload;

      const final = JSON_search_payload.rooms_data.map((res) => {
        return { ...res, roomLength: 1 };
      });

      let residency =
        county.length > 0
          ? county.filter((res) => res.code == JSON_search_payload.residency)[0]
          : "";
      let nationality =
        county.length > 0
          ? county.filter(
              (res) => res.code == JSON_search_payload.nationality
            )[0]
          : "";

      const test = { ...filters };

      let x = {
        date_from: moment(JSON_search_payload.date_from).format("YYYY-MM-DD"),
        date_to: moment(JSON_search_payload.date_to).format("YYYY-MM-DD"),
        checkIn: moment(JSON_search_payload.date_from).format("YYYY-MM-DD"),
        checkOut: moment(JSON_search_payload.date_to).format("YYYY-MM-DD"),
      };
      // localStorage.setItem("filters", JSON.stringify({ ...test, ...x }));
      dispatch({ type: "filter", payload: { ...test, ...x } });

      setHotel({
        ...hotel,
        ...JSON_search_payload,
        rooms: final,
        checkIn: moment(JSON_search_payload.date_from),
        checkOut: moment(JSON_search_payload.date_to),

        goingTo: {
          name: JSON_search_payload.city_name,
          label: JSON_search_payload.city_name,
        },
        currency_code:
          JSON_search_payload.currency_code != ""
            ? {
                label: JSON_search_payload.currency_code,
                value: JSON_search_payload.currency_code,
              }
            : "",
        nationality:
          nationality != ""
            ? {
                label: nationality.name,
                value: nationality.name,
              }
            : "",
        residence:
          residency != ""
            ? {
                label: residency.name,
                value: residency.code,
              }
            : "",
      });
    } else {
      setHotel({
        ...filters,
        checkIn: moment(filters.checkIn),
        checkOut: moment(filters.checkOut),
      });
    }
  }, [county]);

  const [errors, setErrors] = useState({
    rooms: [],
  });
  const [CurrenciesData, setCurrenciesData] = useState();

  const [isErrorLoaded, setIsErrorLoaded] = useState(false);

  useEffect(() => {
    async function fetchCitiesVacation() {
      const Currencies = await fetchAllCurrencies();
      let x = Currencies.map((res) => {
        return { name: res.currency_code, id: res.currency_code };
      });
      setCurrenciesData(x);
    }

    // if (!token_data) {
    fetchCitiesVacation();
    // }
  }, []);

  const offer = localStorage.getItem("hotelOffer");

  useEffect(() => {
    if (Object.values(errors).length > 1) {
      const flag = errors.rooms.every((error) => {
        if (!isFormValid(error)) {
          return false;
        }
        return true;
      });

      if ((isFormValid(errors) && flag) || token_data) {
        const handleSearch = async () => {
          // localStorage.setItem("test", hotel);
          let children = [];
          hotel?.rooms.forEach((resa) => {
            resa?.roomsList?.forEach((res) => {
              children.push({
                number_of_adult: +resa.number_of_adult,
                children: [...res.children],
              });
            });
          });

          let data;
          if (token_data) {
            data = {
              date_from: moment(hotel.checkIn).format("YYYY-MM-DD"),
              date_to: moment(hotel.checkOut).format("YYYY-MM-DD"),
              rooms_data: hotel?.myAds ? hotel?.rooms_data : children,
            };
          } else {
            data = {
              is_umrah: hotelFilters.is_umrah,
              is_vacation: hotelFilters.is_vacation,
              special_code: "",
              country: hotel?.country?.value,
              city_code: hotel?.goingTo?.value,
              date_from: moment(hotel.checkIn).format("YYYY-MM-DD"),
              date_to: moment(hotel.checkOut).format("YYYY-MM-DD"),
              nationality: hotel?.nationality?.code,
              residency: hotel?.residence?.code,
              lang: locale,
              rooms_data: hotel?.myAds ? hotel?.rooms_data : children,
              page: hotelFilters.page,
              currency_code: hotel?.currency_code?.value,
            };

            if (hotel.goingTo && hotel.goingTo.type === "hotel") {
              data.search_filters = hotelFilters.search_filters;
              data.city_code = hotelFilters.city_code;
            }

            if (hotel.goingTo.type === "hotel") {
              data = {
                ...data,
                search_filters: { hotel_ids: [hotel.goingTo.id] },
                city_code: hotel.goingTo.city_id,
              };
            }
          }
          // if (isValid) {
          // if (+hotel.child >= 1) {
          // 	data.rooms_data[0].childrens = [];
          // 	data.rooms_data[0].childrens.push({
          // 		quantity: +hotel.child,
          // 		age: 11,
          // 	});
          // }

          let response;
          if (token_data) {
            response = token_data.is_offer
              ? await guestHotelsOffers({
                  ...data,
                  is_offer: token_data.is_offer,
                  token: token_data.token,
                })
              : await guestHotels({
                  ...data,
                  is_offer: token_data.is_offer,
                  token: token_data.token,
                });
          } else {
            if (+offer) {
              response = await fetchHotelsOffers(data);
            } else {
              response = await fetchHotelsBuilder(data);
            }
          }

          if (response.status === 200) {
            setnight(
              moment(hotel.checkOut).diff(moment(hotel.checkIn), "days")
            );
            if (response.data.data.length > 0) {
              if (!token_data) {
                // localStorage.setItem("hotelFilters", JSON.stringify(data));

                // localStorage.setItem(
                // 	"priceRanges",
                // 	JSON.stringify({
                // 		min: response.data.meta.min_price,
                // 		max: response.data.meta.max_price,
                // 	})
                // );
                // dispatch({ type: "filter", payload: hotel });
                // dispatch({ type: "searchBy", payload: "hotel" });
                // dispatch({ type: "searchResults", payload: response.data });

                dispatch({
                  type: "newSearch",
                  payload: {
                    searchResults: response.data,
                    hotelFilters: data,
                    priceRanges: {
                      min: response.data.filter_data.price.min_price,
                      max: response.data.filter_data.price.max_price,
                    },
                    filters: hotel,
                    searchBy: "hotel",
                  },
                });
                setpage(0);
                // history.push("/market-view");
              } else {
                let x = {
                  date_from: moment(hotel.checkIn).format("YYYY-MM-DD"),
                  date_to: moment(hotel.checkOut).format("YYYY-MM-DD"),
                  checkIn: moment(hotel.checkIn).format("YYYY-MM-DD"),
                  checkOut: moment(hotel.checkOut).format("YYYY-MM-DD"),
                };
                // localStorage.setItem(
                // 	"filters",
                // 	JSON.stringify({ ...filters, ...x })
                // );

                dispatch({
                  type: "newSearch",
                  payload: {
                    searchResults: response.data,
                    hotelFilters: { ...token_data.search_payload, ...data },
                    priceRanges: {
                      min: response.data.filter_data.price.min_price,
                      max: response.data.filter_data.price.max_price,
                    },
                    filters: { ...hotel, ...x },
                    searchBy: "hotel",
                  },
                });

                // history.push("/market-view?token=" + token_data.token);
              }
            } else {
              store.addNotification({
                title: messages.noResults,
                message: messages.noSearchResults,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                  duration: 3000,
                  onScreen: true,
                  pauseOnHover: true,
                },
              });
              dispatch({
                type: "searchResults",
                payload: { data: [] },
              });
            }
          } else {
          }
        };
        handleSearch();
      }
    }
  }, [isErrorLoaded]);

  const setRoomsCount = (name, index, value) => {
    let x = hotel.rooms;
    x[index] = { ...x[index], [name]: value };
    if (name === "roomLength") {
      let rooms = [...Array(+value).keys()].map((res) => ({
        ...res,
        children: [],
      }));
      x[index] = { ...x[index], roomsList: rooms };
    }
    setHotel({ ...hotel, rooms: x });
  };

  const setChildrenCount = (name, index, value) => {
    let x = hotel.rooms;
    let rooms = [...Array(+value).keys()].map((res) => ({
      ...res,
      age: "",
    }));
    let roomsList = x[index].roomsList;
    if (roomsList && roomsList.length > 0) {
      roomsList.map((res) => {
        res.children = rooms;
      });
    }
    x[index] = { ...x[index], [name]: rooms };
    setHotel({ ...hotel, rooms: x });
  };

  const setAge = (index, DD, XX, value) => {
    let x = hotel.rooms;
    let c = x[index].roomsList[DD].children;
    const newArr = c.map((ress, indexs) => {
      if (indexs == XX) {
        return { ...ress, age: +value };
      }
      return { ...ress };
    });
    x[index].roomsList[DD].children = newArr;
    setHotel({ ...hotel, rooms: x });
  };

  const closeAllTaps = () => {
    let list = hotel.rooms.map((res) => {
      return {
        ...res,
        BoxChild: false,
      };
    });
    setHotel({ ...hotel, rooms: list });
  };
  let count =
    hotel.rooms && hotel.rooms.length
      ? hotel.rooms.filter((res) => res.BoxChild).length
      : 0;

  const DetectClickOutside = useDetectClickOutside({
    onTriggered: count > 0 ? closeAllTaps : null,
  });

  const handleSearchCheck = () => {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  const checkFormErrors = () => {
    let roomError = [];

    hotel.rooms.map((res) => {
      let newError = {
        ...validate(
          {
            name: "roomLength",
            value: res.roomLength,
          },
          {
            required: true,
          }
        ),
        ...validate(
          {
            name: "number_of_adult",
            value: res.number_of_adult,
          },
          {
            required: true,
          }
        ),
      };

      roomError.push(newError);
    });

    setErrors({
      ...errors,
      rooms: roomError,
      ...validate(
        { name: "residence", value: hotel.residence },
        { required: true }
      ),
      ...validate(
        { name: "nationality", value: hotel.nationality },
        { required: true }
      ),
      ...validate(
        { name: "goingTo", value: hotel.goingTo },
        { required: true }
      ),
      ...validate(
        { name: "checkIn", value: hotel.checkIn },
        { required: true }
      ),
      ...validate(
        { name: "checkOut", value: hotel.checkOut },
        { required: true }
      ),
      ...validate(
        { name: "checkOut", value: hotel.checkOut },
        { required: true }
      ),
    });
  };
  const handleChange = (e, flag) => {
    const name = e.target?.name;
    const value = e.target?.value;
    if (flag) {
      setHotel({
        ...hotel,
        country: e["value"], // countries[value],
        goingTo: e.value, //countries[value]?.code,
      });
    } else setHotel({ ...hotel, [name]: value });
  };
  let goingToPrevValue = useRef(null);
  function clearSearchText(e) {
    goingToPrevValue.current = hotel.goingTo;
    const value = e.target.value;
    if (value.length > 0) {
      setHotel({ ...hotel, goingTo: null });
    }
  }

  // AutoCompleteField
  const [listAuto, setListAuto] = useState([]);
  const getListAuto = async (inputValue) => {
    if (inputValue.length > 2) {
      const countries = await fetchCitiesHotelsSearch(
        "is_vacation",
        inputValue,
        status === "umrah" ? "966" : ""
      );

      let result = countries.map((item) => {
        if (item.type === "city") {
          return {
            ...item,
            id: item.id,
            name: item.name + " - " + item.country.name[locale],
            value: item.id,
            label: item.name + " - " + item.country.name[locale],
          };
        } else if (item.type === "hotel") {
          return {
            ...item,
            id: item.id,
            country: {
              flag: HotelPlaceHolder,
            },
            name: item.name + " - " + item.city.name[locale],
            value: item.id,
            label: item.name + " - " + item.city.name[locale],
          };
        }
      });
      setListAuto(result);
    }
  };

  const countries = [
    {
      id: 3,
      label: "Makkah",
      name: "Makkah",
      value: 3,
    },
    {
      id: 4,
      label: "Madinah",
      name: "Madinah",
      value: 4,
    },
  ];

  return (
    <div
      className="mybooking-search-wrapper search-bar"
      style={{
        // backgroundImage: `url(${
        // 	status == "umrah" ? bannerUmrah : bannerGlobal
        // })`,
        paddingInline: "20px",
      }}
    >
      <div className="container1">
        <div className="mybooking-grid-container bg-white p-4">
          <div className="col-md-12 HotelSearch">
            <div className="row ">
              <div className="col-md-12 " style={{ padding: "0 15px" }}>
                <div className="row">
                  <div className="col-md-3 px-2 pr-3">
                    <div className="row">
                      <div className="col-md-6 col-6 col-responsive">
                        <SelectField
                          haslabel={false}
                          labelInner={true}
                          label={marketPlace.messages.residency}
                          value={hotel?.residence?.label}
                          name="residence"
                          options={
                            token_data && token_data.residences != null
                              ? token_data.residences.map((res) => {
                                  return {
                                    name: res.name,
                                    id: res.country_code,
                                  };
                                })
                              : county
                          }
                          onChange={(e) => {
                            setHotel({ ...hotel, residence: e });
                          }}
                          onBlur={() =>
                            setErrors({
                              ...errors,
                              ...validate(
                                { name: "residence", value: hotel.residence },
                                { required: true }
                              ),
                            })
                          }
                          color={errors?.residence?.required ? "danger" : ""}
                          errors={errors?.residence}
                        />
                        <a
                          className="link "
                          onClick={() => {
                            setshowRoom(!showRoom);
                          }}
                          href={() => {
                            return false;
                          }}
                        >
                          {showRoom
                            ? marketPlace.hideRooms
                            : marketPlace.ShowRooms}
                        </a>
                      </div>
                      <div className="col-md-6 col-6 col-responsive ">
                        <SelectField
                          haslabel={false}
                          labelInner={true}
                          label={marketPlace.messages.nationality}
                          value={hotel?.nationality?.label}
                          name="nationality"
                          options={
                            token_data && token_data.nationalities != null
                              ? token_data.nationalities.map((res) => {
                                  return {
                                    name: res.name,
                                    id: res.country_code,
                                  };
                                })
                              : county
                          }
                          onChange={(e) => {
                            setHotel({ ...hotel, nationality: e });
                          }}
                          onBlur={() =>
                            setErrors({
                              ...errors,
                              ...validate(
                                {
                                  name: "nationality",
                                  value: hotel.nationality,
                                },
                                { required: true }
                              ),
                            })
                          }
                          color={errors?.nationality?.required ? "danger" : ""}
                          errors={errors?.nationality}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-6 col-responsive ">
                    {+offer ? (
                      <SelectField
                        haslabel={false}
                        labelInner={true}
                        isImage={false}
                        // hasLabel={true}
                        label={marketPlace.messages.destination}
                        options={countries}
                        value={hotel?.goingTo?.label}
                        disabled={token_data}
                        onChange={(e) => {
                          handleChange({ name: "goingTo", value: e }, true);
                          setErrors({
                            ...errors,
                            ...validate(
                              { name: "goingTo", value: e },
                              { required: true }
                            ),
                          });
                        }}
                        color={errors?.goingTo?.required ? "danger" : ""}
                        errors={errors?.goingTo}
                      />
                    ) : (
                      <>
                        <AutoCompleteField
                          //   hasLabel={true}
                          haslabel={false}
                          labelInner={true}
                          isImage={false}
                          flag={hotel.goingTo?.country?.flag}
                          listAuto={listAuto}
                          setListAuto={setListAuto}
                          getListAuto={getListAuto}
                          disabled={token_data}
                          label={marketPlace.messages.destination}
                          isSearchable={true}
                          placeholder={marketPlace.messages.destination}
                          value={hotel.goingTo?.name || ""}
                          onFocus={clearSearchText}
                          onBlur={() =>
                            setHotel({
                              ...hotel,
                              goingTo: goingToPrevValue.current,
                            })
                          }
                          onChange={(e) =>
                            handleChange(
                              { name: "goingTo", value: { name: e } },
                              true
                            )
                          }
                          onSelectValue={(e) => {
                            handleChange({ name: "goingTo", value: e }, true);
                            setErrors({
                              ...errors,
                              ...validate(
                                { name: "goingTo", value: e },
                                { required: true }
                              ),
                            });
                          }}
                          color={errors?.goingTo?.required ? "danger" : ""}
                          errors={errors?.goingTo}
                        />
                      </>
                    )}
                  </div>

                  <div className="col-md-2 col-6 col-responsive ">
                    <div className="mybooking-input-wrapper marketplace-date">
                      <DatePickerField
                        haslabel={false}
                        labelInner={true}
                        label={marketPlace.messages.checkIn}
                        date={hotel?.checkIn}
                        maxDate={
                          token_data
                            ? moment(token_data.end_date).add(1, "d")
                            : false
                        }
                        minDate={
                          token_data
                            ? moment(token_data.start_date).subtract(1, "d")
                            : moment()
                        }
                        onChangeDate={(date) =>
                          setHotel({
                            ...hotel,
                            checkIn: date,
                            checkOut: moment(hotel.checkOut).isAfter(
                              moment(date).add(1, "d")
                            )
                              ? hotel.checkOut
                              : moment(date).add(1, "d"),
                          })
                        }
                        onBlur={() =>
                          setErrors({
                            ...errors,
                            ...validate(
                              { name: "checkIn", value: hotel.checkIn },
                              { required: true }
                            ),
                          })
                        }
                        color={errors?.checkIn?.required ? "danger" : ""}
                        errors={errors?.checkIn}
                      />
                    </div>
                  </div>

                  <div className="col-md-2 col-6 col-responsive ">
                    <div className="mybooking-input-wrapper marketplace-date">
                      <DatePickerField
                        haslabel={false}
                        labelInner={true}
                        label={marketPlace.messages.checkOut}
                        date={
                          hotel.checkOut === null
                            ? moment(hotel.checkIn).add(1, "d")
                            : hotel.checkOut
                        }
                        maxDate={
                          token_data
                            ? moment(token_data.end_date).add(1, "d")
                            : false
                        }
                        minDate={moment(hotel.checkIn)}
                        onChangeDate={(date) =>
                          setHotel({ ...hotel, checkOut: date })
                        }
                        onBlur={() =>
                          setErrors({
                            ...errors,
                            ...validate(
                              { name: "checkOut", value: hotel.checkOut },
                              { required: true }
                            ),
                          })
                        }
                        color={errors?.checkOut?.required ? "danger" : ""}
                        errors={errors?.checkOut}
                      />
                      <a
                        className="link link-mobile "
                        onClick={() => {
                          setshowRoom(!showRoom);
                        }}
                        href={() => {
                          return false;
                        }}
                      >
                        {showRoom
                          ? marketPlace.hideRooms
                          : marketPlace.ShowRooms}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-2 col-6 col-responsive btn-action">
                    <div className="full-input search-button">
                      <div className="full-input search-button">
                        <button
                          onClick={handleSearchCheck}
                          className="btn w-100 bg-nxt py-2"
                          style={{ marginTop: "10px" }}
                          type="button"
                        >
                          {marketPlace.messages.search}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {showRoom ? (
                <>
                  {/* <div className="col-md-7 m-auto">
										<div className="row">
											<div className="col-md-5">
												<SelectField
													label={marketPlace.messages.currencyName}
													value={hotel.currency_code?.label}
													name="currency_code"
													options={CurrenciesData}
													onChange={(e) => {
														setHotel({ ...hotel, currency_code: e });
													}}
													onBlur={() =>
														setErrors({
															...errors,
															...validate(
																{
																	name: "currency_code",
																	value: hotel.currency_code,
																},
																{ required: true }
															),
														})
													}
													color={
														errors?.currency_code?.required ? "danger" : ""
													}
													errors={errors?.currency_code}
												/>
											</div>
										</div>
									</div> */}
                  <div className="col-md-8  m-auto">
                    <label>{marketPlace.rooms}</label>
                  </div>
                  <div className="col-md-8  m-auto bg-Rooms py-3">
                    <div className="col-md-11 m-auto">
                      <div className=" product-build__product-collpase branch-collapse my-0 mb-2">
                        <div className="row">
                          {hotel.rooms && hotel.rooms.length > 0
                            ? hotel.rooms.map((res, index) => {
                                return (
                                  <>
                                    <div
                                      className="col-md-11"
                                      style={{ padding: "0 15px" }}
                                    >
                                      <div className="button-collapse my-2">
                                        <div className="title-style-container">
                                          <div
                                            className="w-100"
                                            onClick={() => {
                                              setRoomsCount(
                                                "BoxChild",
                                                index,
                                                !res.BoxChild
                                              );
                                            }}
                                          >
                                            <div className="w-100">
                                              <p
                                                className="global-input font-weight-bold border-0 "
                                                value=""
                                                type="text"
                                              >
                                                <span className="roomsNumber">
                                                  {res.roomLength} {"  "}
                                                  {marketPlace.messages.room}
                                                </span>
                                                <span className="adultNumber">
                                                  ({res.number_of_adult}{" "}
                                                  {marketPlace.messages.adult}{" "}
                                                  {"  "},{res?.children?.length}{" "}
                                                  {marketPlace.messages.child})
                                                  per room
                                                </span>
                                              </p>
                                            </div>
                                          </div>

                                          <div className="icon-items-section d-flex align-items-center">
                                            <i
                                              onClick={() =>
                                                setRoomsCount(
                                                  "BoxChild",
                                                  index,
                                                  !res.BoxChild
                                                )
                                              }
                                              className={`fas fa-fw text-primary fa-lg ${
                                                res.BoxChild !== true
                                                  ? "fa-chevron-right "
                                                  : "fa-chevron-down"
                                              }`}
                                            ></i>
                                          </div>
                                        </div>
                                        {res.BoxChild ? (
                                          <div
                                            className="row box-child"
                                            ref={DetectClickOutside}
                                            style={{
                                              top: "45px",
                                              bottom: "unset",
                                            }}
                                          >
                                            <div
                                              className="col-md-12"
                                              style={{ padding: "0 15px" }}
                                            >
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <div className="container-centerLabel px-2">
                                                    <label htmlFor="">
                                                      {
                                                        marketPlace.messages
                                                          .rooms
                                                      }
                                                    </label>
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                  <NumberField
                                                    placeholder={
                                                      marketPlace.messages
                                                        .NoOfRooms
                                                    }
                                                    increase={() => {
                                                      setRoomsCount(
                                                        "roomLength",
                                                        index,
                                                        res.roomLength
                                                          ? +res.roomLength + 1
                                                          : 0 + 1
                                                      );
                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        0
                                                      );
                                                    }}
                                                    decrease={() => {
                                                      setRoomsCount(
                                                        "roomLength",
                                                        index,
                                                        res.roomLength - 1
                                                      );
                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        0
                                                      );
                                                    }}
                                                    max={9}
                                                    min={1}
                                                    value={res.roomLength}
                                                    hasLabel={false}
                                                    label={
                                                      marketPlace.messages.rooms
                                                    }
                                                    name="roomLength"
                                                    type="number"
                                                    onChange={(e) => {
                                                      let removeZero =
                                                        e.target.value;
                                                      if (
                                                        e.target.value[0] == 0
                                                      ) {
                                                        removeZero =
                                                          e.target.value.substring(
                                                            1
                                                          );
                                                      }
                                                      setRoomsCount(
                                                        "roomLength",
                                                        index,
                                                        +removeZero > 9
                                                          ? 9
                                                          : removeZero < 1
                                                          ? 1
                                                          : removeZero
                                                      );
                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        0
                                                      );
                                                      // setHotel({
                                                      // 	...hotel,
                                                      // 	rooms_count: e.target.value,
                                                      // });
                                                    }}
                                                    color={
                                                      errors?.rooms[index]
                                                        ?.roomLength?.required
                                                        ? "danger"
                                                        : ""
                                                    }
                                                    errors={
                                                      errors?.rooms[index]
                                                        ?.roomLength
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="col-md-12"
                                              style={{ padding: "0 15px" }}
                                            >
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <div className="container-centerLabel px-2">
                                                    <label
                                                      className="m-0 w-100"
                                                      htmlFor=""
                                                    >
                                                      {
                                                        marketPlace.messages
                                                          .adult
                                                      }
                                                    </label>
                                                    <p className="small-title">
                                                      {marketPlace.ForEveryRoom}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                  <NumberField
                                                    placeholder={
                                                      marketPlace.messages
                                                        .NoOfAdults
                                                    }
                                                    hasLabel={false}
                                                    label={
                                                      marketPlace.messages.adult
                                                    }
                                                    value={res.number_of_adult}
                                                    name="number_of_adult"
                                                    max={6}
                                                    min={1}
                                                    increase={() => {
                                                      setRoomsCount(
                                                        "number_of_adult",
                                                        index,
                                                        res.number_of_adult
                                                          ? +res.number_of_adult +
                                                              1
                                                          : 0 + 1
                                                      );
                                                    }}
                                                    decrease={() => {
                                                      setRoomsCount(
                                                        "number_of_adult",
                                                        index,
                                                        res.number_of_adult - 1
                                                      );
                                                    }}
                                                    onChange={(e) => {
                                                      let removeZero =
                                                        e.target.value;
                                                      if (
                                                        e.target.value[0] == 0
                                                      ) {
                                                        removeZero =
                                                          e.target.value.substring(
                                                            1
                                                          );
                                                      }

                                                      setRoomsCount(
                                                        "number_of_adult",
                                                        index,
                                                        +removeZero > 6
                                                          ? 6
                                                          : removeZero < 1
                                                          ? 1
                                                          : removeZero
                                                      );
                                                    }}
                                                    type={"number"}
                                                    color={
                                                      errors?.rooms[index]
                                                        ?.number_of_adult
                                                        ?.required
                                                        ? "danger"
                                                        : ""
                                                    }
                                                    errors={
                                                      errors?.rooms[index]
                                                        ?.number_of_adult
                                                    }
                                                  />
                                                </div>
                                              </div>{" "}
                                            </div>

                                            <div
                                              className="col-md-12"
                                              style={{ padding: "0 15px" }}
                                            >
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <div className="container-centerLabel px-2">
                                                    <label
                                                      className="m-0 w-100"
                                                      htmlFor=""
                                                    >
                                                      {
                                                        marketPlace.messages
                                                          .child
                                                      }
                                                    </label>
                                                    <p className="small-title">
                                                      {marketPlace.ForEveryRoom}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="col-md-8">
                                                  <NumberField
                                                    placeholder={
                                                      marketPlace.messages
                                                        .NoOfChildren
                                                    }
                                                    hasLabel={false}
                                                    label={
                                                      marketPlace.messages.child
                                                    }
                                                    name="rooms_count"
                                                    max={4}
                                                    min={0}
                                                    value={res?.children?.length.toString()}
                                                    increase={() => {
                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        res.children.length
                                                          ? +res.children
                                                              .length + 1
                                                          : 0 + 1
                                                      );
                                                    }}
                                                    decrease={() => {
                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        res.children.length - 1
                                                      );
                                                    }}
                                                    onChange={(e) => {
                                                      let removeZero =
                                                        e.target.value;

                                                      if (
                                                        e.target.value[0] == 0
                                                      ) {
                                                        removeZero =
                                                          e.target.value.substring(
                                                            1
                                                          );
                                                      }

                                                      setChildrenCount(
                                                        "children",
                                                        index,
                                                        +removeZero > 4
                                                          ? 4
                                                          : removeZero < 0
                                                          ? 0
                                                          : removeZero
                                                      );
                                                    }}
                                                    type={"number"}
                                                    color={
                                                      errors?.rooms[index]
                                                        ?.children?.required
                                                        ? "danger"
                                                        : ""
                                                    }
                                                    errors={
                                                      errors?.rooms[index]
                                                        ?.children
                                                    }
                                                  />
                                                </div>
                                              </div>{" "}
                                            </div>
                                            <div
                                              className="col-md-12"
                                              style={{ padding: "0 15px" }}
                                            >
                                              {res.roomsList &&
                                              res.roomsList.length > 0 &&
                                              res?.children &&
                                              res?.children?.length > 0 ? (
                                                <p className="text-danger-custom my-3">
                                                  {marketPlace.oldChildren}
                                                </p>
                                              ) : (
                                                ""
                                              )}
                                              {res.roomsList &&
                                              res.roomsList.length > 0 &&
                                              res?.children &&
                                              res?.children?.length > 0
                                                ? res.roomsList.map(
                                                    (rese, indexRoom) => {
                                                      return (
                                                        <>
                                                          <div className="row">
                                                            <div className="col-md-3">
                                                              <div className="container-centerLabel px-2">
                                                                <label
                                                                  className="m-0 w-100"
                                                                  htmlFor=""
                                                                >
                                                                  {
                                                                    marketPlace
                                                                      .messages
                                                                      .room
                                                                  }{" "}
                                                                  {+indexRoom +
                                                                    1}
                                                                </label>
                                                              </div>
                                                            </div>
                                                            <div className=" row col-9">
                                                              {rese?.children &&
                                                              rese?.children
                                                                .length > 0
                                                                ? rese?.children.map(
                                                                    (
                                                                      child,
                                                                      indexChild
                                                                    ) => {
                                                                      return (
                                                                        <>
                                                                          <div className="col-md-4 ">
                                                                            <NumberField
                                                                              placeholder={`${
                                                                                marketPlace
                                                                                  .messages
                                                                                  .child
                                                                              } ${
                                                                                indexChild +
                                                                                1
                                                                              }`}
                                                                              hasLabel={
                                                                                false
                                                                              }
                                                                              value={
                                                                                child.age
                                                                              }
                                                                              max={
                                                                                12
                                                                              }
                                                                              min={
                                                                                1
                                                                              }
                                                                              name="setAge"
                                                                              onChange={(
                                                                                e
                                                                              ) => {
                                                                                let removeZero =
                                                                                  e
                                                                                    .target
                                                                                    .value;

                                                                                setAge(
                                                                                  index,
                                                                                  indexRoom,
                                                                                  indexChild,
                                                                                  +removeZero >
                                                                                    12
                                                                                    ? 12
                                                                                    : removeZero <
                                                                                      1
                                                                                    ? 1
                                                                                    : removeZero
                                                                                );
                                                                              }}
                                                                              type={
                                                                                "number"
                                                                              }
                                                                              color={
                                                                                ""
                                                                              }
                                                                              errors={
                                                                                ""
                                                                              }
                                                                              onEnter={(
                                                                                e
                                                                              ) =>
                                                                                false
                                                                              }
                                                                              increase={() => {
                                                                                setAge(
                                                                                  index,
                                                                                  indexRoom,
                                                                                  indexChild,
                                                                                  +child.age +
                                                                                    1
                                                                                );
                                                                              }}
                                                                              decrease={() => {
                                                                                setAge(
                                                                                  index,
                                                                                  indexRoom,
                                                                                  indexChild,
                                                                                  +child.age -
                                                                                    1
                                                                                );
                                                                              }}
                                                                            />
                                                                          </div>
                                                                        </>
                                                                      );
                                                                    }
                                                                  )
                                                                : ""}
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )
                                                : ""}
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                    <div className="col-md-1">
                                      {index > 0 ? (
                                        <span
                                          className="span-remove"
                                          onClick={() => {
                                            let rooms = [...hotel.rooms];
                                            rooms.splice(index, 1);
                                            setHotel({
                                              ...hotel,
                                              rooms: rooms,
                                            });
                                          }}
                                        >
                                          <i className="fas fa-times"></i>
                                        </span>
                                      ) : null}
                                    </div>
                                  </>
                                );
                              })
                            : null}
                        </div>
                        <div
                          className="col-md-12 mt-4"
                          style={{ height: "10px" }}
                        >
                          <p
                            onClick={() => {
                              let x = hotel.rooms;
                              x.push({});
                              setHotel({ ...hotel, rooms: x });
                              setRoomsCount(
                                "roomLength",
                                +hotel.rooms.length - 1,
                                1
                              );
                              setRoomsCount(
                                "number_of_adult",
                                +hotel.rooms.length - 1,
                                2
                              );
                            }}
                            className="text-success  text-right"
                          >
                            {marketPlace.AddNewRoom}
                            <i className="fas fa-plus mx-1"></i>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="col-md-2 col-6 col-responsive btn-action-mobile">
                <div className="full-input search-button">
                  <div className="full-input search-button">
                    <button
                      onClick={handleSearchCheck}
                      className="btn w-100 bg-nxt py-2"
                      style={{ marginTop: "10px" }}
                      type="button"
                    >
                      {marketPlace.messages.search}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBar;
