import HeroContent from "./MainContent";
import { useWebBuilderState } from "context/webBuilder";
import Carousel from "react-multi-carousel";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";

function Hero() {
  const { hero } = useWebBuilderState();
  const preview = true;

  const bgImage = hero?.mainContent?.backgroundImage ?? "";
  const sliders = hero?.mainContent?.sliders ?? [];
  const hasSlider = hero?.mainContent?.hasSlider;

  const contentLayout = hero?.mainContent?.layout?.layoutType;


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={`web-builder-hero`}
      style={{
        ...(contentLayout === 1 &&
          !hasSlider && {
          backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${bgImage?.src ? bgImage.src : bgImage
            })`,
        }),
      }}
    >
      {hasSlider ? (
        <Carousel
          responsive={responsive}
          itemClass={"web-builder-slide"}
          slidesToSlide={1}
          keyBoardControl={true}
          customRightArrow={<CustomRightArrow />}
          customLeftArrow={<CustomLeftArrow />}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          renderButtonGroupOutside={false}
          showDots={true}
          infinite={true}
        >
          {sliders.map((slide) => {
            return (
              <img src={slide.src || slide} key={slide.id || slide} alt="" />
            );
          })}
        </Carousel>
      ) : null}
      <div className={`hero-content-container ${!preview ? "justify-content-between" : ""}`}>
        <HeroContent />
      </div>

    </div>
  );
}

export default Hero;
