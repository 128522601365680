import { useWebBuilderState } from "context/webBuilder";
import React, { useRef } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import { Link } from 'react-router-dom';
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import Locale from "translations";

function HeroHeaderV2({ editHeader }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { hero, mainMenu, style } = useWebBuilderState();
  const navRef = useRef(null);

  // const location = useLocation();
  const preview = true;

  const header = hero?.header;
  const layoutType = header?.layout?.layoutType;
  const hasButton = header?.layout?.hasButton;
  const hasLogo = header?.layout?.hasLogo;
  const headerBg = header?.layout?.background === "white";
  const call_us = header?.layout?.callUs;
  const visibleMenuItems = mainMenu?.filter((item) => item?.visible);

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const history = useHistory();
  const params = useParams();

  // scroll to location from view package page
  // useEffect(() => {
  //   if (location.state) {
  //     setTimeout(() => {
  //       scrollToSection(location.state);
  //     }, 500);
  //   }
  // }, []);

  const nav = (
    <nav className="header-nav" ref={navRef}>
      <button className="close-nav" onClick={handleOpenMobileNav}>
        <CloseIcon />
      </button>
      <ul className="main-nav">
        <li
          onClick={() => {
            if (preview) {
              if (params.name) {
                history.push(`/public-page-v2/${params.name}`);
              } else {
                history.push(`/`);
              }
            }
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.home}
        </li>
        {visibleMenuItems?.map((item) => {
          return (
            <li
              key={item?.id}
              className="position-relative"
              onClick={() => {
                if (!item?.subNavs || item?.subNavs?.length === 0) {
                  let url = true
                    ? `/${item.url}`
                    : `/web-builder-v2/preview/${item?.url}`;
                  if (preview) {
                    history.push({ pathname: `${url}` });
                  }
                  handleOpenMobileNav();
                }
              }}
            >
              {item?.subNavs?.length > 0 ? null : <span>{item?.title?.[locale]}</span>}
              {/********************************************** sub navs *************************************************************/}
              {item?.subNavs?.length > 0 && (
                <UncontrolledDropdown>
                  <DropdownToggle caret className={`service-dropdown`}>
                    {item?.title?.[locale]}
                  </DropdownToggle>

                  <DropdownMenu right>
                    {item?.subNavs.map((subNav) => (
                      <DropdownItem
                        key={subNav.id}
                        onClick={() => {
                          let url = true
                            ? `/${subNav.url}`
                            : `/web-builder-v2/preview/${subNav?.url}`;
                          if (preview) {
                            history.push({ pathname: `${url}` });
                          }
                          handleOpenMobileNav();
                        }}
                      >
                        {subNav.title?.[locale]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );

  const logo = header?.logo ? <img src={header?.logo} alt="Logo" /> : null;


  const clickContactButton = () => {
    if (preview) {
      scrollToSection(header?.contactUs?.id);
      return;
    }
  };

  function handleOpenMobileNav() {
    if (navRef.current.classList.contains("nav-opened")) {
      navRef.current.classList.remove("nav-opened");
    } else {
      navRef.current.classList.add("nav-opened");
    }
  }

  const contactButton = hasButton && (
    <div className="d-flex-language-contact d-flex align-items-center gap-10">
      <button
        className="contact-button mr-2"
        onClick={() => {
          // check if we inside view package page when click scroll back to preview page the scroll using useeffect
          history.push({ pathname: `/`, state: header?.contactUs?.id });
          clickContactButton();

        }}
      >
        {header?.contactUs?.name}
      </button>
      <LanguageSwitcher show={false} />
      <CurrencySwitcher show={false}/>

      {call_us && (
        <div className="d-flex align-items-center ml-2 call-us">
          <PhoneIcon color="#1e85ff" />
          <div className="webBuilder-contact d-flex flex-column ml-1">
            <p>{webBuilder.callUs}</p>
            <p className="body text-primary-web-builder">{header?.phoneNumber}</p>
          </div>
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={`web-builder-hero-header`} onClick={editHeader}>
        {layoutType === 1 || layoutType === 2 ? (
          <header className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}>
            {hasLogo && (
              <div className="logo">
                {header?.layout?.hasLogo ?
                  <Link to="/">
                    {logo}
                  </Link>

                  : null
                }
              </div>
            )}
            {(style?.theme === "theme-1" || style?.theme === "theme-3") && (
              <>
                {nav}
                {contactButton}
                <button
                  className="nav-mobile-icon"
                  onClick={handleOpenMobileNav}
                >
                  <MenuIcon />
                </button>
              </>
            )}
            {style?.theme === "theme-2" && (
              <div className="nav-container">
                {nav}
                {contactButton}
                <button
                  className="nav-mobile-icon"
                  onClick={handleOpenMobileNav}
                >
                  <MenuIcon />
                </button>
              </div>
            )}
          </header>
        ) : (
          <header className={`${layoutType === 4 ? "flex-row-reverse " : ""}`}>
            {contactButton}

            {hasLogo && (
              <div className="logo">
                {header?.layout?.hasLogo ?
                  <Link to="/">
                    {logo}
                  </Link>
                  :
                  null
                }
              </div>
            )}
            {nav}
          </header>
        )}
      </div>
    </>
  );
}

export default HeroHeaderV2;

// export function MobileNav() {
// 	return (
// 		<ul></ul>
// 	)
// }
