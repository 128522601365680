import React from 'react'
import Carousel from "react-multi-carousel";
import CustomRightArrow from 'modules/WebBuilder-V2/shared/CustomRightArrow';
import CustomLeftArrow from 'modules/WebBuilder-V2/shared/CustomLeftArrow';
import ServiceCardWithButton from 'modules/WebBuilder-V2/shared/ServiceCardWithButton';



export default function HotelCarouselView({ items }) {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };

  return (
    <Carousel
      responsive={responsive}
      itemClass={"hotel-slide"}
      className=""
      slidesToSlide={1}
      keyBoardControl={true}
      customRightArrow={<CustomRightArrow />}
      customLeftArrow={<CustomLeftArrow />}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      renderArrowsWhenDisabled={true}
      infinite={true}
    >
      {items.map((props, index) => (
        <div className="px-2" key={props.id || index}>
          <ServiceCardWithButton index={index} {...props} cardType="hotels" />
        </div>
      ))}
    </Carousel>
  )
}