import React from "react";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";
import validate from 'helpers/validate'
import { useSBSState } from "context/global";


const fetchFlag = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function ContactInfo({ contactInfoData, setContactInfoData, errors, setErrors }) {
  const { allCountries } = useSBSState();
  const { userDetails, teamManagement, backOffice, webBuilder } = Locale;
  // const countries = allCountries
  //   ? allCountries?.map((country) => {
  //       return { ...country, label: country?.name };
  //     })
  //   : null;
  return (
    <div className="contact-info mb-4">
      <h3>{webBuilder.contactInformation}</h3>
      <div className="alert alert-danger">
        Enter your contact information to be in touch with you for any booking updates
      </div>
      <div className="row px-0 mx-0">
        {/* fullname */}
        <div className="col-md-12">
          <TextField
            placeholder={teamManagement.fullNamePlaceholder}
            label={teamManagement.fullName}
            type={"text"}
            name="fullName"
            id="fullName"
            value={contactInfoData?.contact_fullname}
            onChange={(e) => {
              setContactInfoData({ ...contactInfoData, contact_fullname: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "contact_fullname", value: e.target.value },
                  { required: true }
                ),
              });
            }}
            errors={errors?.contact_fullname}
            color={errors?.contact_fullname?.required ? "danger" : ""}
          />
        </div>
        {/* email */}
        <div className="col-md-6">
          <TextField
            type="text"
            label={backOffice.email}
            placeholder={backOffice.emailPlaceholder}
            name="email"
            id="email"
            value={contactInfoData?.contact_email}
            onChange={(e) => {
              setContactInfoData({ ...contactInfoData, contact_email: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "contact_email", value: e.target.value },
                  { required: true, email: true }
                ),
              });
            }}
            errors={errors?.contact_email}
            color={errors?.contact_email?.required || errors?.contact_email?.email ? "danger" : ""}
          />
        </div>
        <div className="col-md-6 phone">
          {/* phone code  */}
          <label className="mb-0">{userDetails.phoneNumber}</label>
          <div className="d-flex gap-10">
            <div className="phone_code ">
              <SelectField
                label={"Code"}
                name="phonecode"
                id="phonecode"
                placeholder="select"
                value={
                  contactInfoData.contact_phoneCode?.flag ?
                    <img src={`${fetchFlag}/${contactInfoData.contact_phoneCode?.flag}`} width="30px" alt='' />
                    :
                    contactInfoData.contact_phoneCode?.phone_code
                }
                // value={contactInfoData?.contact_phoneCode?.phone_code}
                onChange={(e) => {
                  setContactInfoData({ ...contactInfoData, contact_phoneCode: e });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "contact_phoneCode", value: e },
                      { required: true, }
                    ),
                  });
                }}
                options={allCountries}
                errors={errors?.contact_phoneCode}
                color={errors?.contact_phoneCode?.required ? "danger" : ""}
              />
            </div>
            <div className="phone_number">
              <TextField
                label={userDetails.phoneNumber}
                placeholder={userDetails.phoneNumberPlaceholder}
                type="number"
                name="phone"
                id="phone"
                className="phone_number_textfield control-field__input w-100"
                value={contactInfoData?.contact_phone}
                min={9}
                max={13}
                onWheel={(e) => e.currentTarget.blur()}
                onChange={(e) => {
                  setContactInfoData({ ...contactInfoData, contact_phone: e.target.value });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "contact_phone", value: e.target.value },
                      { required: true, min: 9, max: 13 }
                    ),
                  });
                }}
                errors={errors?.contact_phone}
                color={errors?.contact_phone?.required || errors?.contact_phone?.min || errors?.contact_phone?.max ? "danger" : ""}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
