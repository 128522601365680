import { useMarketplaceState } from 'context/marketplace';
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import Locale from 'translations';

export default function TourSelectDates({ tourData, selectedCategory, selectedDate, setSelectedDate, tourSummary }) {
  const { marketPlace } = Locale;
  const { toursSearch } = useMarketplaceState();
  const operationDays = tourData?.content?.operationDays?.map(day => day?.code);
  const operationDates = selectedCategory?.rate?.operationDates;
  const startOperationDate = operationDates?.[0];
  const endOperationDate = operationDates?.[operationDates?.length - 1];


  const memoCalender = useMemo(() => {
    let weekDaysNames = [];
    let availabeDates = [];

    let startDate = moment(toursSearch?.date_from);
    let endDate = moment(toursSearch?.date_to);
    startDate = startDate.subtract(startDate?.day(), "days");
    endDate = endDate.add(6 - endDate?.day(), "days");
    const weekStart = moment().startOf('week');
    for (let i = 0; i <= 6; i++) {
      weekDaysNames.push(moment(weekStart).add(i, 'days').format("dd"));
    }

    for (var date = moment(startDate); date <= (endDate); date.add(1, 'day')) {
      availabeDates.push({
        fullDate: moment(date),
        singleDay: moment(date).format("DD-MMM").split("-"),
        operationDay: moment(date).format("ddd").toLocaleUpperCase(),
      });
    }

    return { weekDaysNames, availabeDates }
  }, [toursSearch?.date_from, toursSearch?.date_to]);

  function checkIsDateValid(date, operationDay) {
    const isDateInSearchDates = date?.isBetween(moment(toursSearch?.date_from), moment(toursSearch?.date_to), 'days', '[]');
    // date week days in operation days (sun, mon, tue, ....)
    const isDateInOperationDays = operationDays?.length > 0 ? operationDays?.includes(operationDay) : true;
    // date in rates details operations dates
    const isDateInOperationDates = date?.isBetween(moment(startOperationDate?.from), moment(endOperationDate?.from), 'days', '[]');
    const dateIsValid = isDateInSearchDates && isDateInOperationDays && isDateInOperationDates;

    return { isDateInSearchDates, isDateInOperationDays, isDateInOperationDates, dateIsValid };
  }

  // set selected date
  useEffect(() => {
    function checkIfDateIsOperationDay() {
      
      if (operationDays?.includes(moment(startOperationDate?.from).format('ddd').toLocaleUpperCase()) && moment(startOperationDate?.from).isAfter(selectedDate)) {
        setSelectedDate(startOperationDate?.from);
      } else {
        for (const date of memoCalender?.availabeDates) {
          const dateIsValid = checkIsDateValid(date?.fullDate, date?.operationDay).dateIsValid;
          if (dateIsValid && !selectedDate) {
            setSelectedDate(date?.fullDate);
            break;
          } else if (dateIsValid && !selectedDate && moment(startOperationDate?.from).isBefore(selectedDate)) {
            setSelectedDate(startOperationDate?.from);
          } else if (dateIsValid && moment(endOperationDate?.from).isBefore(selectedDate)) {
            setSelectedDate(endOperationDate?.from);
          }
        }
      }
    }
    checkIfDateIsOperationDay();
  }, [memoCalender?.availabeDates, toursSearch, selectedCategory])

  return (
    <section className='tour-details-select-dates'>
      <h3 className='mb-2 font-weight-bold'>{marketPlace.selectDates}</h3>
      <div className="tour-details-dates-calender">
        <div className="tour-details-dates-calender-days">
          <ul className='d-flex justify-content-around'>
            {memoCalender?.weekDaysNames?.map((day, index) => {
              return (
                <li key={day + "" + index}>
                  {day}
                </li>
              )
            })}
          </ul>
        </div>
        <div className='tour-details-dates-calender-weekdayes'>
          <ul className='d-flex flex-wrap text-center'>
            {memoCalender?.availabeDates?.map((date, index) => {
              const isDisabled = !checkIsDateValid(date?.fullDate, date?.operationDay).dateIsValid;
              const dayIsSelected = date?.fullDate.format("YYYY-MM-DD") === moment(selectedDate).format("YYYY-MM-DD");

              return (
                <li key={index}
                  className={`${isDisabled ? "disabled" : ""} ${dayIsSelected ? "selected" : ""}`}
                  onClick={() => {
                    setSelectedDate(date?.fullDate);
                  }}
                >
                  <span>{date?.singleDay[0]}</span>
                  <span>{date?.singleDay[1]}</span>
                  <span className='price'>{(+tourSummary?.total)?.toFixed(2)}</span>
                  <span className='price'>{tourData?.currency}</span>
                </li>
              )
            })}
          </ul>

        </div>
      </div>
    </section>
  )
}
