import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-white.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-white.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-white.svg";

import moment, { now } from "moment";
import { useHistory, Link } from "react-router-dom";
import { useWebBuilderState } from "context/webBuilder";

export default function PackageCardwithImage(props) {
  const {
    image,
    name,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    price,
    locationName,
    initialName,
  } = props;
  const { style } = useWebBuilderState();
  const history = useHistory();

  return (
    <Link to={product_uuid ? `/package/${product_uuid}` : "/"} key={product_uuid}>
      <article className={`package-card-container ${!image ? "empty-service" : ""}`} style={{ backgroundImage: `url(${image})`, }}>
        <div className="package-card-details">
          <div className="price">
            <p>From</p>
            <span className="amount">
              {price} <span className="currency"> {props?.currency}</span>
            </span>
          </div>
          <div className="package-info">
            <div className="rate">
              <div className="starts">
                {[...Array(5)].map((i, index) =>
                  index < stars ? (
                    <GoldenStarIcon key={`package-rate-${index}`} />
                  ) : (
                    <StarIcon key={`package-rate-${index}`} />
                  )
                )}
              </div>
              <p className="font-weight-bold">{name || initialName}</p>
            </div>
            {style?.theme !== "theme-3" ?
              <div className="location">
                <LocationIcon /> <span className="ml-2">{locationName}</span>
              </div>
              :
              null
            }
            <div className="date">
              <CalendarIcon />{" "}
              <span className="ml-2">
                {`
							${moment(departure_date_at || now()).format("DD MMM YYYY")} - 
							${moment(return_date_at || now()).format("DD MMM YYYY")}`}
              </span>
            </div>
            {hotelName ? (
              <div className="hotel">
                <Hotelcon /> <span className="ml-2">{hotelName}</span>
              </div>
            ) : null}
          </div>
        </div>
      </article>
    </Link>
  );
}


