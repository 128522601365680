
import { useWebBuilderDispatch } from "context/webBuilder";
import HeroHeaderV2 from "modules/WebBuilder-V2/Components/Content/Hero/Header";
import { changeBorder, changeColorTheme, changeFont, changeFontColor, changeTitle } from "modules/WebBuilder-V2/helpers/styles";
import HeroHeader from "modules/WebBuilder/Components/Content/Hero/Header";
import { useEffect, useState } from "react";
import { getPageBuilderByCompany } from "services/webBuilder";

export default function DashboardLayout(props) {
  const dispatch = useWebBuilderDispatch();
  const [pageDataLoaded, setPageDataLoaded] = useState({isLoaded: false, theme: ""});

  const updateStyle = ({ name, value }) => {
    dispatch({
      type: "updateStyle",
      payload: {
        name,
        value,
      },
    });
  };
  const getPageDetails = async () => {
    let res;

    res = await getPageBuilderByCompany(process.env.REACT_APP_WebsiteName);

    if (res?.status === 200) {
      let pageBuilderData = res.data.page_builder_config;
      dispatch({
        type: "setInitialData",
        payload: { ...pageBuilderData, has_marketplace: res?.data?.has_marketplace, active_modules: res?.data?.active_modules },
      });
      const style = pageBuilderData?.style;
      changeColorTheme(style?.color, updateStyle);
      changeBorder(style?.rounded, updateStyle);
      changeFont(style?.font, updateStyle);
      changeFontColor(style?.fontColor || "#000", updateStyle)
      changeTitle(res?.data?.name?.en);
      setPageDataLoaded({ isLoaded: true, theme: style?.theme})
    }
  };

  useEffect(() => {
    getPageDetails();

    return () => { };
  }, []);


  return (
    //vh-100
    <div className={`bg-white web-builder-preview `} id="main-web-builder">
      {/* <Header /> */}
      {pageDataLoaded.isLoaded ?
        <div className="" {...props}>
          {pageDataLoaded?.theme === "theme-old" ? <HeroHeader /> : <HeroHeaderV2 />}

          {props.children}
        </div>
        :
        null
      }
    </div>
  );
}
