import ServicesWithImage from "./ServicesWithImage";
import ServicesWithIcons from "./ServicesWithIcons";

function ServiceCard({
  icon,
  typeTitle,
  body,
  type,
  visible,
  editCardDetails,
  ourServicesType,
  header,
}) {

  const preview = true;
  const isTransfer = type === "transport";
  const isPackage = type === "packages";

  const logo = icon ? <img src={icon} alt="Logo" /> : null;

  return (
    <div className={`web-builder-content-our-service_card h-100`}>
      <div className={`${isTransfer ? "opacity-50 user-select-none pe-defualt" : "h-100"}`}>
        {ourServicesType === "with_images" ? (
          <ServicesWithImage
            logo={logo}
            type={type}
            isTransfer={isTransfer}
            isPackage={isPackage}
            editCardDetails={editCardDetails}
            header={header}
            body={body}
            preview={preview}
            visible={visible}
            typeTitle={typeTitle}
          />
        ) : (
          <ServicesWithIcons
            logo={logo}
            type={type}
            isTransfer={isTransfer}
            isPackage={isPackage}
            editCardDetails={editCardDetails}
            header={header}
            body={body}
            preview={preview}
            visible={visible}
            typeTitle={typeTitle}
          />
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
