import { useWebBuilderState } from 'context/webBuilder'
import { ReactComponent as FacebookIcon } from 'assets/images/webBuilder/Facebook.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/webBuilder/Twitter.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/webBuilder/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'assets/images/webBuilder/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram.svg'
import { useHistory, Link } from 'react-router-dom'
import Locale from 'translations'

const currentYear = new Date().getFullYear()

export default function FooterTempTwo() {
  const { webBuilder } = Locale;
  const history = useHistory()

  const { footer: footerDetails, hero, ourServices } = useWebBuilderState()
  const header = hero?.header;
  const hasLogo = header?.layout?.hasLogo;
  const services = ourServices?.services?.filter(service => service?.visible);


  const type = 'footer'


  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    youtube: <YouTubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedInIcon />
  }


  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const navFooter = (
    <>
      <div className='footer-col'>
        <h4 className='footerLinks-header'>Home</h4>
        <ul>
          <li
            onClick={() => {
              history.push({ pathname: `/`, state: 'about-us-section' });
              scrollToSection('about-us-section')
            }}
            className={'text-secondary-color'}
          >
            About Us
          </li>

          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: 'our-service'
              })
              scrollToSection('our-service')
            }}
            className={'text-secondary-color'}
          >
            Our Service
          </li>
        </ul>
      </div>

      <div className='footer-col d-none-mob'>
        <h4 className='footerLinks-header'>Get Help</h4>
        <ul>
          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: 'faq-section'
              })
              scrollToSection('faq-section')
            }}
            className={'text-secondary-color'}
          >
            FAQ
          </li>

          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: 'contact-us-section'
              })
              scrollToSection('contact-us-section')
            }}
            className={'text-secondary-color'}
          >
            Contact Us
          </li>
        </ul>
      </div>

      <div className='footer-col'>
        <h4 className='footerLinks-header'>Our Service</h4>
        <ul>
          {services && services.length > 0 && services?.map(service => {
            return (
              <li
                onClick={() => {
                  history.push({
                    pathname: `/`,
                    state: service?.id
                  })
                  scrollToSection(service?.id)
                }}
                className={'text-secondary-color'}
              >
                Available {service?.type}
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )

  const logo = header?.logo ? <img className='footer-photo' src={header?.logo} alt='Logo' /> : null

  return (
    <div className="w-100 px-md-5">
      <div className='footer-temp-two'>
        <div className='footerTempTwo-logo'>
          {hasLogo && header?.layout?.hasLogo ?
            <Link to="/">
              {logo}
            </Link>
            :
            null}
          <div className='footer-socialIcons'>
            <p className='footer-text'>{webBuilder.followUsOn}</p>

            <div className='d-flex align-items-center gap-10 py-2'>
              {footerDetails?.links?.map((link) => {
                if (link?.visible) {
                  return (
                    <div key={link?.id}>
                      <a href={link?.url} target='_blank' rel='noreferrer'>
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>
        </div>

        <div className='footerTempTwo-links'>{navFooter}</div>
      </div>
      <div className='footer-copyrights'>
        <p>
          {webBuilder.allRightsReserved} {" - "}
          <a href="https://safasoft.com/" target="_blank" rel="noreferrer" className="text-white">{webBuilder.safasoft}</a>
          {" "} © {currentYear}
        </p>

      </div>
    </div>
  )
}
