import { NoDataIcon } from "modules/WebBuilder-V2/Components/Content/OurServices/icons";
import React from "react";

import ResultItem from "./resultItem";
import Locale from "translations";

export default function SearchResultData({
  resultData,
  productType,
  filter,
  setFilter,
  handleSort
}) {
  const { webBuilder } = Locale;
  return (
    <div className="search-result">
      <div className="header">
        <p>
          {resultData?.length > 0
            ? `${resultData[0].locationName} - ${productType?.name}`
            : null}
        </p>
        <div className="select-box">
          <p>{webBuilder.sortBy}</p>
          <select
            className="sort-price-select"
            placeholder="Best Price"
            onChange={(e) => {
              setFilter({ ...filter, sortBy: e.target.value })
              handleSort(e.target.value)
            }}
          >
            {/* <option value="null">Best Price</option> */}
            <option value="asc" selected={filter?.sortBy === "asc"}> {webBuilder.lowestPrice}</option>
            <option value="desc" selected={filter?.sortBy === "desc"}>{webBuilder.highestPrice}</option>
          </select>
        </div>
      </div>
      <div className="result">
        {resultData?.length > 0 ? (
          resultData?.map((item, index) => {
            return (
              <ResultItem
                key={item?.id || index}
                item={item}
                proudctType={productType}
              />
            );
          })
        ) : (
          <div className="no-result">
            <NoDataIcon />
            <h6>{webBuilder.noResultsFound}</h6>
            <p>{webBuilder.youCanTryAnotherSearch}</p>
            <button className="btn">{webBuilder.backtoSearch}</button>
          </div>
        )}
      </div>
    </div>
  );
}
