// import Image from "next/image";
import React from "react";
import Carousel from "react-multi-carousel";
// import LeftArrow from "../../../../Icons/LeftArrow";
// import RightArrow from "../../../../Icons/RightArrow";
// import styles from "../../../../styles/umrah/modals/hotel.module.css";

const arrowStyleLeft = {
  border: 0,
  background: 'none',
  position: 'absolute',
  left: '5px',

};
const arrowStyleRight = {
  border: 0,
  background: 'none',
  position: 'absolute',
  right: '5px',

};
const CustomRight = ({ onClick }) => (
  <button className="arrow right" onClick={onClick} style={arrowStyleRight}>
    {/* <RightArrow /> */}
    <i className="fas fa-arrow-circle-right fa-1x"></i>
  </button>
);
const CustomLeft = ({ onClick }) => (
  <button className="arrow left" onClick={onClick} style={arrowStyleLeft}>
    {/* <LeftArrow /> */}
    <i className="fas fa-arrow-circle-left fa-1x"></i>
  </button>
);

function ImageGallery({ images }) {

  return (
    <div className={`w-100`}>
      <Carousel
        additionalTransfrom={-12}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        // className={`${styles.image_gallery}`}
        containerClass="container"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite
        itemClass={`image_gallery_item`}
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        customRightArrow={<CustomRight />}
        customLeftArrow={<CustomLeft />}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 1,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 1,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 1,
          },
        }}
        rewind={false}
        rewindWithAnimation={false}
        // rtl={true}
        shouldResetAutoplay
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {images?.map((i) => (
          <img key={i} src={i.src || i} fill alt={i.alt || ""} />
        ))}
      </Carousel>
    </div>
  );
}

export default ImageGallery;
