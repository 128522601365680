import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import PackageCard from "./PackageCard";
import Carousel from "react-multi-carousel";
import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";
import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";
import OurPackagesWithImage from "./PackagesWithImage";


function OurPackages({ details, onFocus, focusContainer }) {
  const { title, body, items, hasSlider } = details;


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };
  const CustomRightArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={onClick}
    >
      <RightICon />
    </button>
  );

  const CustomLeftArrow = ({ onClick }) => (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={onClick}
    >
      <LeftIcon className="r-left-icon" />
    </button>
  );
  return (
    <>
      {items?.filter(item => item?.product_uuid)?.length > 0 ?
        hasSlider ? (
          <div className="web-builder-content-our-packages-with-slider container">
            <div className="our-packages-header">
              <ServicesContent
                header={title}
                body={body}
              />
              <button className="btn ">View All Package</button>
            </div>
            
            {items.length > 0 && (
              <div className="all-web-builder-our-service-carousel">
                <Carousel
                  responsive={responsive}
                  itemClass={"service-slide"}
                  className="web-builder-our-service-carousel"
                  slidesToSlide={1}
                  keyBoardControl={true}
                  customRightArrow={<CustomRightArrow />}
                  customLeftArrow={<CustomLeftArrow />}
                  renderButtonGroupOutside={false}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  infinite={true}
                >
                  {items?.filter(item => item?.product_uuid).map((props, index) => (
                    <PackageCard {...props} key={props.id} id={props.id} />
                  ))}
                </Carousel>

                <button className="btn btn-mobile ">View All Package</button>
              </div>
            )}
          </div>
        ) : (
          <OurPackagesWithImage details={details} />
        )
        :
        null
      }
    </>
  );
}

export default OurPackages;
