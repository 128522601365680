
import { useSBSDispatch, useSBSState } from "context/global";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import currencyImg from 'assets/images/market-place/servicesIcon/currency_exchange.png'


export default function CurrencySwitcher({ show }) {
  const {  currency } = useSBSState();
  const selectedCurrencyCode = currency; 

  const dispatch = useSBSDispatch();
  const currencyState = [
    {
      id: 47,
      name: "Indonesian Rupiah",
      currency_code: "IDR",
      decimal_digits: 0,
      rounding: 0,
      symbol: "Rp",
      symbol_native: "Rp",
      name_plural: "Indonesian rupiahs",
      sort: 1,
      status: 1,
      created_at: null,
      updated_at: null,
    },
    {
      id: 95,
      name: "Saudi Riyal",
      currency_code: "SAR",
      decimal_digits: 2,
      rounding: 0,
      symbol: "SR",
      symbol_native: "ر.س.",
      name_plural: "Saudi riyals",
      sort: 44,
      status: 1,
      created_at: null,
      updated_at: null,
    },
  ];
  function selectCurrency(currency) {
    dispatch({
      type: "setCurrency",
      payload: currency?.currency_code
    });
    // toggleCurrenciesModal();
    // setSearchTerm('');
    // setCurrenciesList(allCurrencies);

  }
  return (
    <UncontrolledDropdown
      className={`lang-dropdown p-0 ${show ? "visibility-hidden" : ""}`}
    >
      <DropdownToggle caret className="btn-light lang-btn currency-switcher">
        <div >
          {/* <CurrencyHeaderIcon/> */}
          <img
            className="img-lang1 p-1 "
            src={currencyImg}
            alt="currency"
            width="28"
          />
          <span className="mx-2">

          {selectedCurrencyCode}
          </span>
        </div>
      </DropdownToggle>

      <DropdownMenu className="lang-dropdown-item">
        {currencyState?.map((currency) => {
          const isSelectedCurrency = currency?.currency_code === selectedCurrencyCode;

          return (
            <DropdownItem
              key={currency?.currency_code}
              onClick={() => {
                !isSelectedCurrency && selectCurrency(currency)
              }}
            >

              <span className="ms-2">{currency?.currency_code}</span>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
