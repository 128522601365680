import { useState } from "react";
import { useSBSState } from "context/global";
import Locale from "translations";


function AboutUs({ details }) {
  const { locale } = useSBSState();
  const { items, id } = details;

  const AboutItems = {
    content: AboutContent,
    media: AboutMedia,
  };

  return (
    <div id={id}>
      <div className="web-builder-content-about-us container">
        {items.map((item) => {
          const componentType = item.id.includes("media") ? "media" : "content";
          const Component = AboutItems[componentType];

          return (
            <Component
              key={item.id}
              id={item.id}
              details={{ ...item, head: item?.head?.[locale], body: item?.body?.[locale] }}
            />
          );
        })}
      </div>
    </div>
  );
}

export default AboutUs;

const AboutContent = ({ details }) => {
  const { webBuilder } = Locale;
  const { title, head, body, textAlign = "start", readMoreText, readLessText } = details;

  let trimBody = body ? body?.length > 400 ? `${body?.substr(0, 400)}...` : body : "";
  const [isFullContentShown, setIsFullContentShown] = useState(false);

  function toggleContent() {
    setIsFullContentShown(!isFullContentShown)
  }

  return (
    <div className="about-us-details" style={{ textAlign }}>
      <p className="about-us-title">
        {webBuilder.aboutus}
      </p>

      <p className="h4">
        {head}
      </p>

      <p className="text-body mt-3">
        {!isFullContentShown ? trimBody : body}
      </p>

      {body?.length > 400 ?
        <button className="btn web-builder-filled-btn mt-3" onClick={toggleContent}>
          {isFullContentShown ? readLessText : readMoreText}
        </button>
        :
        null
      }
    </div>
  );
};

const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType } = details;

  return (
    <>
      <div className={`about-media-container ${mediaType === "image" ? "about-media-image" : ""}`}>
        {mediaType === "video" ? (
          <iframe
            style={{ maxWidth: "100%" }}
            className="w-100 h-100"
            width="auto"
            height="auto"
            src={`https://www.youtube.com/embed/${mediaUrl}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        ) : (
          <img src={mediaUrl} className="img-fluid" alt="about us" />
        )
        }
      </div>
    </>
  );
};
