import moment from "moment";
import _ from "lodash";

const formatTime = (filters) => {
	// eslint-disable-next-line no-undef
	const cloneFilters = _.cloneDeep(filters)
	delete cloneFilters.page

	if (cloneFilters.departureTime?.to && cloneFilters.departureTime?.from) {
		cloneFilters.departureTime.to = moment(
			cloneFilters.departureTime?.to
		).format("HH:mm");
		cloneFilters.departureTime.from = moment(
			cloneFilters.departureTime?.from
		).format("HH:mm");
	} else {
		cloneFilters.departureTime = {};
	}

	if (cloneFilters.arrivalTime?.to && cloneFilters.arrivalTime?.from) {
		cloneFilters.arrivalTime.to = moment(cloneFilters.arrivalTime?.to).format(
			"HH:mm"
		);
		cloneFilters.arrivalTime.from = moment(
			cloneFilters.arrivalTime?.from
		).format("HH:mm");
	} else {
		cloneFilters.arrivalTime = {};
	}

	if (cloneFilters.durationTime?.to && cloneFilters.durationTime?.from) {
		cloneFilters.durationTime.to = moment(cloneFilters.durationTime?.to).format(
			"HH:mm"
		);
		cloneFilters.durationTime.from = moment(
			cloneFilters.durationTime?.from
		).format("HH:mm");
	} else {
		cloneFilters.durationTime = {};
	}

	return cloneFilters;
};
export const formatSearchData = (data, filters) => {
	const type = data.type;
	let formatedData;

	if (type === "1") {
		formatedData = {
			cacheKey: data.cacheKey,
			adults: data.adults,
			children: data.children,
			airline: data.airline?.id ?? "",
			airLegs: [
				{
					cabinClass: data.cabinClass?.name,
					departureDate: moment(data.departureDate).format("YYYY-MM-DD"),
					origin: data?.from?.code,
					destination: data?.to?.code,
				},
				{
					cabinClass: data.cabinClass?.name,
					departureDate: moment(data.arrivalDate).format("YYYY-MM-DD"),
					origin: data?.to?.code,
					destination: data?.from?.code,
				},
			],
		};
		if (filters) {
			// eslint-disable-next-line no-undef

			formatedData = {
				...formatedData,
				filter: formatTime(filters),
			};
		}
	}

	if (type === "2") {
		formatedData = {
			cacheKey: data.cacheKey,
			adults: data.adults,
			children: data.children,
			airline: data.airline?.id,
			airLegs: [
				{
					cabinClass: data.cabinClass?.name,
					departureDate: moment(data.departureDate).format("YYYY-MM-DD"),
					origin: data?.from?.code,
					destination: data?.to?.code,
				},
			],
		};
		if (filters) {
			formatedData = {
				...formatedData,
				filter: formatTime(filters),
			};
		}
	}

	if (type === "3") {
		formatedData = {
			cacheKey: data.cacheKey,
			adults: data.adults,
			children: data.children,
			airline: data.airline?.id,
			airLegs: data.airLegs.map((res) => {
				return {
					cabinClass: data.cabinClass?.name,
					departureDate: moment(res.data).format("YYYY-MM-DD"),
					origin: res?.from?.code,
					destination: res?.to?.code,
				};
			}),
		};
		if (filters) {
			formatedData = {
				...formatedData,
				filter: formatTime(filters),
			};
		}
	}

	return formatedData;
};
