import axios from "axios";
import { cleanEmpty } from './general';
const baseURL = process.env.REACT_APP_API_URL + "/v1";
const companySlug = process.env.REACT_APP_WebsiteName;
const currentDomain = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port;
// tours
export const searchTours = async (data, page) => {
  return await axios
    .post(`${baseURL}/tours/page-builder/${companySlug}/search?page=${page}`, data,)
    .then((res) => res)
    .catch((err) => err.response);
};
export const showSingleTourDetails = async (data) => {
  return await axios
    .post(`${baseURL}/tours/page-builder/${companySlug}/show`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const getCart = async (id) => {
  return await axios
    .get(`${baseURL}/tours/page-builder/cart/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const addTourToCart = async (data) => {
  return await axios
    .post(`${baseURL}/tours/page-builder/add-to-cart`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const deleteTourFromCart = async (data) => {
  return await axios
    .delete(`${baseURL}/tours/page-builder/cart`, { params: cleanEmpty(data) })
    .then((res) => res)
    .catch((err) => err.response);
};
export const getFiltersLookup = async (cacheKey) => {
  return await axios
    .get(`${baseURL}/tours/page-builder/side-filters/${cacheKey}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const bookTours = async (data) => {
  axios.defaults.headers.common["X-Payment-Redirect-URL"] = currentDomain;
  return await axios
    .post(`${baseURL}/tours/page-builder/${companySlug}/book`, data)
    .then((res) => res)
    .catch((err) => err.response);
};