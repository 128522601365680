import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import React, { useEffect, useState } from "react";
import SearchBarHorizontal from "./SearchBarHorizontal";
import SearchBarVertical from "./SearchBarVertical";
import { useHistory } from "react-router-dom";
import { handleSearch } from "modules/WebBuilder-V2/helpers/handleSearch";
import { store } from "react-notifications-component";
import { useSBSState } from "context/global";

function HeroContent() {
  const { locale, allCountries } = useSBSState();
  const { hero, ourServices, content, style, has_marketplace, active_modules } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();
  const mainContent = hero?.mainContent;
  const [countriesLookup, setCountriesLookup] = useState([]);
  const history = useHistory();
  const preview = true;
  const [searchState, setSearchState] = useState({
    country: null,
    proudctType: null,
  });

  const layoutType = hero?.mainContent?.layout?.layoutType;
  const searchbar_position = hero?.mainContent?.searchbarPosition;

  const visibleServices = ourServices
    ? ourServices?.services
      ?.filter((service) => service?.visible && service?.type !== "packages")
      ?.map((service) => ({
        id: service?.item_id,
        name: service?.type,
      }))
    : [];

  const product_types = [
    { id: 1, name: "Hajj" },
    { id: 2, name: "Umrah" },
    { id: 3, name: "Umrah-plus" },
    { id: 4, name: "Tourism" },
    ...visibleServices,
  ];

  function handleSearchInputs(value, keyName) {
    if (
      keyName === "proudctType" &&
      (value.id === 1 || value.id === 2 || value.id === 3)
    ) {
      setCountriesLookup(() =>
        countriesLookup.filter((country) => country?.id === 966)
      );
    } else {
      setCountriesLookup(allCountries);
    }
    setSearchState({
      ...searchState,
      country:
        keyName === "proudctType" &&
          (value.id === 1 ||
            value.id === 2 ||
            value.id === 3)
          ? {
            id: 966,
            name: "Saudi Arabia",
            code: "SA",
            nat_code: "SAR",
            currency: "SAR",
            nationality_id: 966,
            image: "3a00e3ac-a4c1-443f-84d9-0aef27ec9bbb",
            flag: "f4e1fb09-cc9e-473a-828f-02fb764d8394",
            phone_code: "+966",
            value: 966,
            label: "Saudi Arabia",
          }
          : searchState.country,
      [keyName]: value,
    });
  }

  function getSearchResults() {
    let searchResults = handleSearch(
      searchState?.country?.id,
      searchState?.proudctType,
      content
    );
    if (searchResults?.length > 0) {
      dispatch({
        type: "addPackagesResult",
        payload: searchResults,
      });
      if (true) {
        history.push(
          `/packages-results?country=${searchState?.country?.id}&type=${searchState?.proudctType?.id}`
        );
      } else {
        history.push(
          `/web-builder-v2/preview/packages-results?country=${searchState?.country?.label}&type=${searchState?.proudctType?.id}`
        );
      }
    } else {
      store.addNotification({
        title: "No data found!",
        message: "No data matches the specified search criteria",
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }
  useEffect(() => {
    if (allCountries) {
      setCountriesLookup(allCountries);
    }
  }, [allCountries]);

  function renderSearchForm() {
    return {
      center: (
        <SearchBarHorizontal
          product_types={product_types}
          countries={countriesLookup}
          searchState={searchState}
          handleSearchInputs={handleSearchInputs}
          handleSearch={getSearchResults}
          preview={preview}
          has_marketplace={has_marketplace && active_modules?.length > 0}
        />
      ),
      start: (
        <SearchBarVertical
          product_types={product_types}
          countries={countriesLookup}
          searchState={searchState}
          handleSearchInputs={handleSearchInputs}
          handleSearch={getSearchResults}
          preview={preview}
          has_marketplace={has_marketplace && active_modules?.length > 0}
        />
      ),
    };
  }

  return (
    <div
      className={`web-builder-hero-content content-layout-${layoutType} content-layout-${style?.theme} justify-content-${searchbar_position}`}
    >
      <p className="header h3">{mainContent?.title?.[locale]}</p>
      <p className="body">{mainContent?.body?.[locale]}</p>

      {renderSearchForm()?.[searchbar_position]}
    </div>
  );
}

export default HeroContent;
