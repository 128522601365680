import React, { useCallback, useEffect, useMemo, useState } from "react";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useWebBuilderState } from "context/webBuilder";
import { MinusIcon, PlusIcon } from "components/Icons";
import GlobalIcon from "assets/images/webBuilder/global.svg";
import EditIcon from "assets/images/webBuilder/form-edit-icon.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";
import WhatsappIcon from "assets/images/webBuilder/whatsapp.svg";
import { ReactComponent as ProfileIcon } from "assets/images/webBuilder/profile.svg";
import { useParams } from "react-router-dom";
import { sendRequestPackage } from "services/webBuilder";
import { store } from "react-notifications-component";
import validate, { isFormValid } from "helpers/validate";
import { useSBSState } from "context/global";
import Locale from "translations";

const roomTypesLookup = {
  "1": "Single",
  "2": "Double",
  "3": "Triple",
  "4": "Quadruple",
  "5": "Quintuple",
  "6": "Hexagonal",
  "7": "heptathlon",
  "8": "suite",
  "9": "flat"
}




export default function WebBuilderRequestForm({ packageData, packageContainHotel }) {
  const { webBuilder } = Locale;
  const { allCountries } = useSBSState();
  const { id } = useParams();
  const { style } = useWebBuilderState();

  let isFullPackagePrice = packageData?.product_price?.product_price_type?.id === 1;
  // states
  const [requestFrom, setRequestForm] = useState({
    first_name: "",
    last_name: "",
    adults: 1,
    children: 0,
    nationality: null,
    email: "",
    phone_code: "",
    phone: "",
    whatsapp_number: "",
    message: "",
    room_type: null,
    reservation_category: null,
  });

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [roomsTypes, setRoomsTypes] = useState([]);




  const calcPackagePriceWithHotel = useCallback(() => {
    let adultPrice = 0;
    let childPrice = 0;

    let combinations = packageData?.product_price_combinations;
    let hotelCombination = combinations?.find((comb) => +comb?.combinations[0]?.room_type === requestFrom.room_type?.room_type);
    // for private category
    if (requestFrom.reservation_category?.id === "private") {
      adultPrice = isFullPackagePrice
        ? hotelCombination?.full_price_after_discount * requestFrom?.room_type?.room_type
        : hotelCombination?.breakdown_price_after_discount * requestFrom?.room_type?.room_type;
    }
    // for shared category
    if (requestFrom.reservation_category?.id === "shared") {
      adultPrice = isFullPackagePrice
        ? hotelCombination?.full_price_after_discount * +requestFrom?.adults
        : hotelCombination?.breakdown_price_after_discount * +requestFrom?.adults;
    }
    childPrice = hotelCombination?.child_price_after_discount * +requestFrom.children;

    return { adultPrice, childPrice }
  }, [
    isFullPackagePrice,
    packageData?.product_price_combinations,
    requestFrom?.adults,
    requestFrom.children,
    requestFrom.reservation_category,
    requestFrom.room_type?.room_type
  ]);


  const calcPackagePriceWithoutHotel = useCallback(() => {
    let adultPrice = 0;
    let childPrice = 0;
    let serviceCombination = packageData?.product_price_combinations[0];
    adultPrice = isFullPackagePrice
      ? serviceCombination?.full_price_after_discount * +requestFrom?.adults
      : serviceCombination?.breakdown_price_after_discount * +requestFrom?.adults;

    childPrice = serviceCombination?.child_price_after_discount * +requestFrom.children;
    return { adultPrice, childPrice }
  }, [isFullPackagePrice, packageData?.product_price_combinations, requestFrom?.adults, requestFrom.children])


  function createRoomsTypes() {
    let roomAvailabilitiesFormat = packageData?.roomsAvailabilities?.filter((room) => room?.room_remaining_availability > 0)
      .map((room) => {
        return {
          id: room?.id,
          room_type: room?.room_type,
          name: roomTypesLookup?.[room?.room_type],
          value: room?.room_type,
          label: roomTypesLookup?.[room?.room_type],
        };
      });
    setRoomsTypes(roomAvailabilitiesFormat);
  };

  function checkFormErrors() {
    let submitErrors = {};
    Object.keys(requestFrom).forEach(key => {
      if (key !== "message" && key !== "children") {
        submitErrors = {
          ...submitErrors,
          ...validate(
            { name: key, value: requestFrom[key] },
            {
              required: !packageContainHotel && (key === "room_type" || key === "reservation_category") ? false : true,
              email: key === "email",
            }
          )
        }
      }
    });
    setErrors(() => submitErrors)
  }

  async function sendRequet() {
    const data = {
      package_uuid: id,
      ...requestFrom,
      phone: `${requestFrom.nationality?.phone_code}${requestFrom.phone}`,
      whatsapp_number: `${requestFrom.nationality?.phone_code}${requestFrom.whatsapp_number}`,
      nationality: requestFrom?.nationality?.label,
      adults: +requestFrom.adults,
      children: +requestFrom.children,
      room_type: requestFrom.room_type?.label,
      reservation_category: requestFrom?.reservation_category?.id || "shared"
    }

    if (process.env.REACT_APP_WebsiteName) {
      const res = await sendRequestPackage(process.env.REACT_APP_WebsiteName, data);
      if (res.status === 200) {
        store.addNotification({
          message: res?.data?.message || "Email Sent Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setRequestForm({
          first_name: "",
          last_name: "",
          adults: 1,
          children: 0,
          nationality: null,
          email: "",
          phone_code: "",
          phone: "",
          whatsapp_number: "",
          message: "",
          room_type: null
        });
      }
    }
  }
  async function onSubmitForm(e) {
    e.preventDefault();
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      sendRequet();
    }
  }, [isErrorLoaded]);

  useEffect(() => {
    if (!packageData?.roomAvailabilities) {
      createRoomsTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageData]);


  const calcTotalPrices = useMemo(() => {
    if (!packageData) return;
    if (packageContainHotel && requestFrom.room_type) {
      return calcPackagePriceWithHotel();
    }
    if (!packageContainHotel) {
      return calcPackagePriceWithoutHotel()
    }

  }, [calcPackagePriceWithHotel, calcPackagePriceWithoutHotel, packageContainHotel, packageData, requestFrom.room_type]);


  return (
    <form className="request-form" onSubmit={(e) => e.preventDefault()}>
      <div className="header">{webBuilder.requestForm}</div>
      <div className="row mx-0">
        {/* first name */}
        <div className="col-md-6 col-12">
          <TextField
            haslabel={false}
            id="first_name"
            name="first_name"
            placeholder={webBuilder.firstName}
            label={webBuilder.firstName}
            value={requestFrom.first_name}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, first_name: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "first_name", value: e.target.value },
                  { required: true }
                ),
              })
            }}
            isImage
            image={EditIcon}
            prependimage
            color={errors?.first_name?.required ? "danger" : ""}
            errors={errors?.first_name}
          />
        </div>
        {/* last name */}
        <div className="col-md-6 col-12">
          <TextField
            haslabel={false}
            id="last_name"
            name="last_name"
            placeholder={webBuilder.lastName}
            label={webBuilder.lastName}
            value={requestFrom.last_name}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, last_name: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "last_name", value: e.target.value },
                  { required: true }
                ),
              })
            }}
            // isImage
            // image={EditIcon}
            color={errors?.last_name?.required ? "danger" : ""}
            errors={errors?.last_name}
          />
        </div>
        {/* adult */}
        <div className="col-12 mt-3 ">
          <div className="pax-box d-flex justify-content-between">
            <div className="d-flex align-items-center gap-10">
              <ProfileIcon width="25px" height="25px" />
              <span>{webBuilder.adults}</span>
            </div>
            <div>
              {/* decrease */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, adults: +requestFrom?.adults - 1 })}
                disabled={requestFrom.adults === 1}
              >
                <MinusIcon color={`rgb(${style?.color})`} />
              </button>
              <input
                type="number"
                id="form-adults"
                className=""
                value={requestFrom.adults}
                disabled
              />
              {/* increase */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, adults: +requestFrom?.adults + 1 })}
                disabled={requestFrom.adults === requestFrom?.room_type?.room_type}
              >
                <PlusIcon color={`rgb(${style?.color})`} />
              </button>
            </div>

          </div>
        </div>
        {/* children */}
        <div className="col-12 mt-3 ">
          <div className="pax-box d-flex justify-content-between">
            <div className="d-flex align-items-center gap-10">
              <ProfileIcon width="25px" height="25px" />
              <span>{webBuilder.children}</span>
            </div>
            <div>
              {/* decrease */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, children: +requestFrom?.children - 1 })}
                disabled={requestFrom.children === 0}
              >
                <MinusIcon color={`rgb(${style?.color})`} />
              </button>
              <input
                type="number"
                id="form-children"
                className=""
                value={requestFrom.children}
              />
              {/* increase */}
              <button type="button" className="btn px-0 shadow-none"
                onClick={() => setRequestForm({ ...requestFrom, children: +requestFrom?.children + 1 })}
              >
                <PlusIcon color={`rgb(${style?.color})`} />
              </button>
            </div>
          </div>
        </div>

        {/* Nationality */}
        <div className="col-md-6 col-12 mt-3">
          <SelectField
            haslabel={false}
            id="nationality"
            name="nationality"
            placeholder={webBuilder.nationality}
            label={webBuilder.nationality}
            options={allCountries}
            value={requestFrom.nationality?.name || ""}
            onChange={(e) => {
              setRequestForm({ ...requestFrom, nationality: e, phone_code: e?.phone_code })
              setErrors({
                ...errors,
                ...validate(
                  { name: "nationality", value: e },
                  { required: true }
                ),
              })
            }}
            isImage
            image={GlobalIcon}
            prependimage
            color={errors?.nationality?.required ? "danger" : ""}
            errors={errors?.nationality}
          />
        </div>

        {/* email */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="email"
            haslabel={false}
            id="form-email"
            name="form-email"
            placeholder={webBuilder.email}
            label={webBuilder.email}
            value={requestFrom.email}
            isImage
            image={SmsIcon}
            prependimage
            onChange={(e) => {
              setRequestForm({ ...requestFrom, email: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "email", value: e.target.value },
                  { required: true, email: true }
                ),
              });
            }}
            color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
            errors={errors?.email}
          />
        </div>

        {/* phone  */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="number"
            haslabel={false}
            id="form-phone"
            name="form-phone"
            placeholder={webBuilder.phone}
            label={webBuilder.phone}
            value={requestFrom.phone}
            isImage
            image={MobileIcon}
            prependimage
            extraText={requestFrom.phone_code || "---"}
            extraTextPosition="prepend"
            onChange={(e) => {
              setRequestForm({ ...requestFrom, phone: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "phone", value: e.target.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.phone?.required ? "danger" : ""}
            errors={errors?.phone}
          />
        </div>
        {/* whats app */}
        <div className="col-md-6 col-12 mt-3">
          <TextField
            type="number"
            haslabel={false}
            id="form-whatsapp_number"
            name="form-whatsapp_number"
            placeholder={webBuilder.whatsapp}
            label={webBuilder.whatsapp}
            value={requestFrom.whatsapp_number}
            isImage
            image={WhatsappIcon}
            prependimage
            extraText={requestFrom.nationality?.phone_code || "---"}
            extraTextPosition="prepend"
            onChange={(e) => {
              setRequestForm({ ...requestFrom, whatsapp_number: e.target.value });
              setErrors({
                ...errors,
                ...validate(
                  { name: "whatsapp_number", value: e.target.value },
                  { required: true }
                ),
              });
            }}
            color={errors?.whatsapp_number?.required ? "danger" : ""}
            errors={errors?.whatsapp_number}
          />
        </div>
        {/* notes */}
        <div className="col-12 mt-3">
          <textarea
            id="form-notes"
            name="form-notes"
            cols="30"
            rows="3"
            className="textarea"
            placeholder={webBuilder.note}
            value={requestFrom.message}
            onChange={(e) => setRequestForm({ ...requestFrom, message: e.target.value })}
          />
        </div>
        {packageContainHotel &&
          <>
            <div className="col-12">
              <hr />
              <SelectField
                label={webBuilder.roomType}
                haslabel={false}
                placeholder={webBuilder.roomType}
                value={requestFrom.room_type?.label}
                options={roomsTypes}
                onChange={(e) => {
                  setRequestForm({ ...requestFrom, room_type: e, adults: 1 });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "room_type", value: e },
                      { required: true }
                    ),
                  })
                }}
                color={errors?.room_type?.required ? "danger" : ""}
                errors={errors?.room_type}
              />
            </div>
            <div className="col-12">
              <hr />
              <SelectField
                label={webBuilder.reservationCategory}
                haslabel={false}
                placeholder={webBuilder.reservationCategory}
                value={requestFrom?.reservation_category?.name}
                options={[{ name: webBuilder.private, id: "private" }, { name: webBuilder.shared, id: "shared" }]}
                onChange={(e) => {
                  setRequestForm({ ...requestFrom, reservation_category: e });
                  setErrors({
                    ...errors,
                    ...validate(
                      { name: "reservation_category", value: e },
                      { required: true }
                    ),
                  })
                }}
                color={errors?.reservation_category?.required ? "danger" : ""}
                errors={errors?.reservation_category}
              />
            </div>
          </>

        }

        {/* prices */}
        <div className="prices">
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-center">
              <ProfileIcon />
              {webBuilder.adult}
            </span>
            <span className="total-price">
              {calcTotalPrices?.adultPrice.toFixed(2) || 0} {packageData?.product_price?.currency_code}
            </span>
          </div>
          <hr className="my-3" />
          <div className="d-flex justify-content-between align-items-center">
            <span className="d-flex align-items-center">
              <ProfileIcon />
              {webBuilder.child}
            </span>
            <span className="total-price">
              {calcTotalPrices?.childPrice.toFixed(2) || 0} {packageData?.product_price?.currency_code}
            </span>
          </div>
        </div>

        {/* submit btn */}
        <div className="col-12 mt-3">
          <button type="button" className="btn submit-btn w-100"
            onClick={onSubmitForm}
          >
            {webBuilder.sendRequest}
          </button>
        </div>

      </div>
    </form>
  )
}
