import React, { useState } from "react";



import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import { sendGetInTouch } from "services/webBuilder";
import { store } from "react-notifications-component";
import Locale from "translations";

function ContactUsWithImage({ details }) {
  const { webBuilder } = Locale;
  const { id, body, title, image } = details;

  const preview = true;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    notes: "",
  });


  const handleFormData = ({ name, value }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSubmitForm = async (e) => {
    e.preventDefault();
    const data = {
      ...formData,
      first_name: formData.name,
      message: formData.notes,
    };
    if (process.env.REACT_APP_WebsiteName) {
      const res = await sendGetInTouch(process.env.REACT_APP_WebsiteName, data);
      if (res.status === 200) {
        store.addNotification({
          message: res?.data?.message || "Email Sent Successfully",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
            pauseOnHover: true,
          },
        });
        setFormData({
          name: "",
          email: "",
          phone: "",
          notes: "",
        });
      }
    }
  };


  return (
    <div className="web-builder-content-contact-us-with-image" id={id}>
      <div className="container">
        <div className="contact-us-layout">
          <div className="contact-us-data">
            <div className="content-top-section">
              <p>{body}</p>
              <p>{title}</p>
            </div>

            <form onSubmit={onSubmitForm} className="contact-us-form">
              <div className="contact-us-fields">
                <TextField
                  haslabel={false}
                  placeholder={webBuilder.name}
                  value={formData.name}
                  onChange={(e) =>
                    handleFormData({ name: "name", value: e.target.value })
                  }
                  isImage
                  image={ProfileIcon}
                  prependimage
                  required
                />
              </div>
              <div className="contact-us-fields">
                <TextField
                  haslabel={false}
                  placeholder={webBuilder.email}
                  type="email"
                  value={formData.email}
                  onChange={(e) =>
                    handleFormData({ name: "email", value: e.target.value })
                  }
                  isImage
                  image={SmsIcon}
                  prependimage
                  required
                />
              </div>

              {/* phone  */}
              <div className="contact-us-fields">
                <TextField
                  type="number"
                  haslabel={false}
                  id="form-phone"
                  name="form-phone"
                  placeholder={webBuilder.phone}
                  value={formData.phone}
                  isImage
                  image={MobileIcon}
                  prependimage
                  required
                  onChange={(e) =>
                    handleFormData({ name: "phone", value: e.target.value })
                  }
                />
              </div>
              <textarea
                rows={5}
                placeholder={webBuilder.message}
                value={formData.notes}
                onChange={(e) =>
                  handleFormData({ name: "notes", value: e.target.value })
                }
                required
                className="textarea-field"
              />

              <button
                type={preview ? "submit" : "button"}
                className="contact-us-submit-button"
              >
                {webBuilder.submit}
              </button>
            </form>
          </div>
          <div className="contact-us-image">
            {image ? <img src={image} alt="Logo" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsWithImage;
