import { useWebBuilderState } from 'context/webBuilder'
import React from 'react'
import CenteredFooter from './CenteredFooter'
import FooterTempTwo from './FooterTempTwo'

export default function Footer({ focusContainer, handleFocus }) {

	const { footer: footerDetails } = useWebBuilderState()
	const footer_position = footerDetails?.footerPosition;


	function renderFooter() {
		return {
			center: <CenteredFooter
				focusContainer={focusContainer}
				handleFocus={handleFocus}
			/>,
			
			between: <FooterTempTwo
				focusContainer={focusContainer}
				handleFocus={handleFocus}
			/>,
		};
	}

	return (
		<footer className='footer-container'>
			{renderFooter()?.[footer_position]}
		</footer>
	)
}
