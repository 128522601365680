import React from "react";
import SearchService from "./SearchService";
import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";

const SearchBarHorizontal = ({ product_types, countries, preview, searchState, handleSearchInputs, handleSearch, has_marketplace }) => {
  const { webBuilder } = Locale;
  return (
    <div className={`search-form-web-builder search-form-horizontal mt-3 ${has_marketplace ? "market-place-search" : ""}`}>
      {has_marketplace ?
        <SearchService />
        :
        <>
          <div className="search-form-select">
            <SelectField
              haslabel={false}
              placeholder={webBuilder.selectCountry}
              id="search-country"
              name="search-country"
              options={countries}
              value={searchState?.country?.label}
              onChange={(e) => handleSearchInputs(e, "country")}
            />
          </div>

          <div className="search-form-select">
            <SelectField
              haslabel={false}
              placeholder={webBuilder.packageType}
              id="search-product-type"
              name="search-product-type"
              options={product_types}
              value={searchState.proudctType?.label}
              onChange={(e) => handleSearchInputs(e, "proudctType")}
            />
          </div>


          <button className='btn search-form-button'
            onClick={() => preview && handleSearch()}
          >
            {webBuilder.search}
          </button>
        </>
      }


    </div>
  );
};

export default SearchBarHorizontal;
