import React from 'react'
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import { Link } from 'react-router-dom';


export default function PackageBreadCrumb() {
  // const params = useParams();
  // const link = params.name ? `/` : `/`;
  return (
    <div className='search-bread-crumb'>
      <Link className='home' to={'/'}>Home</Link>
      <span> <ChevronDown /></span>
      <span className='search'>Search Results</span>
    </div>
  )
}
