import { useWebBuilderState } from 'context/webBuilder'
import { ReactComponent as FacebookIcon } from 'assets/images/webBuilder/Facebook.svg'
import { ReactComponent as TwitterIcon } from 'assets/images/webBuilder/Twitter.svg'
import { ReactComponent as YouTubeIcon } from 'assets/images/webBuilder/youtube.svg'
import { ReactComponent as LinkedInIcon } from 'assets/images/webBuilder/linkedin.svg'
import { ReactComponent as InstagramIcon } from 'assets/images/webBuilder/instagram.svg'
import { useHistory, Link } from 'react-router-dom'
import Locale from 'translations'

const currentYear = new Date().getFullYear()


export default function CenteredFooter() {
  const { webBuilder } = Locale;
  const history = useHistory()

  const { footer: footerDetails, hero } = useWebBuilderState()
  const header = hero?.header
  const hasLogo = header?.layout?.hasLogo
  const type = 'footer'

  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    youtube: <YouTubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedInIcon />
  }


  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const nav = (
    <nav className='nav-list'>
      <ul className='links-list'>
        <li
          onClick={() => {
            history.push(`/`)
            scrollToSection('main-web-builder')
          }}
          className={'text-secondary-color'}
        >
          {webBuilder.home}
        </li>

        <li
          onClick={() => {
            history.push({ pathname: `/`, state: 'contact-us-section' })
            scrollToSection('contact-us-section')
          }}
          className={'text-secondary-color'}
        >
          {webBuilder.getHelp}
        </li>

        <li
          onClick={() => {
            history.push({
              pathname: `/`,
              state: 'web-builder-our-service'
            })
            scrollToSection('web-builder-our-service')
          }}
          className={'text-secondary-color'}
        >
          {webBuilder.ourServices}
        </li>
      </ul>
    </nav>
  )

  const logo = header?.logo ? <img className='footer-photo' src={header?.logo} alt='Logo' /> : null

  return (
    <>
      <div className='container'>
        <div className='web-builder-footer'>
          <div className='d-flex align-items-center footer-icon footer-logo justify-content-center'>
            {hasLogo && header?.layout?.hasLogo ?
              <Link to="/">
                {logo}
              </Link>
              :
              null
            }
          </div>

          <div className='footer-links'>{nav}</div>

          {/* social icons */}
          <div className='footer-socialIcons'>
            <p className='footer-text'>{webBuilder.followUsOn}</p>

            <div className='d-flex align-items-center gap-10 py-2'>
              {footerDetails?.links?.map((link) => {
                if (link?.visible) {
                  return (
                    <div key={link?.id}>
                      <a href={link?.url} target='_blank' rel='noreferrer'>
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  )
                } else {
                  return null
                }
              })}
            </div>
          </div>

          <div className='footer-copyrights align-items-center'>
            <p>
              {webBuilder.allRightsReserved} {" - "}
              <a href="https://safasoft.com/" target="_blank" rel="noreferrer" className="text-white">{webBuilder.safasoft}</a>
              {" "} © {currentYear}
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
