import PackageBreadCrumb from "modules/WebBuilder-V2/PackageSearch/breadCrumb";
import React, { useEffect, useState } from "react";
import {
  CheckIcon,
  LocationIcon,
  MapSMIcon,
} from "../../Flight/Icons";
import Carousel from "react-spring-3d-carousel";
import MapModel from "../Models/mapModel";
import CancellationModel from "../Models/CancellationModel";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import { useSBSState } from "context/global";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import noHotel from "assets/images/placeHoleder.png";
import { fetchHotelBuilder } from "services/webBuilder";
import Room from "./Room";

export default function HotelDetails() {
  const [mapIsOpen, setMapIsOpen] = useState(false);
  const [cancellationIsOpen, setCancellationIsOpen] = useState(false);
  const toggleMap = () => {
    setMapIsOpen((prev) => !prev);
  };
  const toggleCancellation = () => {
    setCancellationIsOpen((prev) => !prev);
  };
  const search = window.location.search;
  const name = new URLSearchParams(search);
  const [params, setParams] = useState(search);
  const { webBuilder } = Locale;
  const history = useHistory();
  const { id } = useParams();
  const { locale } = useSBSState();

  const [Count, setCount] = useState(0);

  const [selectedDataReq, setSelectedDataReq] = useState([]);
  const { filters, hotelFilters } = useMarketplaceState();

  const selectedHotelSearch = JSON.parse(
    localStorage.getItem("selectedHotelSearch")
  );
  const dispatch = useMarketplaceDispatch();
  const [hotel, setHotel] = useState(null);
  const [images, setImages] = useState(null);
  const [active, setActive] = useState(0);
  const [Silders, setSilders] = useState([]);
  const [selectedHotel, setSelectedHotel] = useState({
    roomsCacheKey: null,
    name: null,
    address: null,
    from: null,
    to: null,
    adult: 1,
    child: 0,
    type: null,
    quantity: 1,
    price: null,
  });

  // const handleChange = (e, i) => {
  // 	const name = e.target.name;
  // 	const value = e.target.value;
  // 	setSelectedHotel({ ...selectedHotel, [name]: value });
  // };

  const book = (selected) => {
    dispatch({
      type: "selected",
      payload: { ...selectedHotel, ...selected },
    });
  };

  // TODO: children
  useEffect(() => {
    async function fetchData() {
      let isSubscribed = true;

      if (params.includes("token")) {
        // let x = {};
        // name.forEach(function (value, key) {
        // 	x = { ...x, [key]: value };
        // });
        // let response;
        // console.log(selectedHotelSearch,"selectedHotelSearch");
        // response = await fetchHotelGuest({
        // 	date_from: filters.date_from,
        // 	date_to: filters.date_to,
        // 	...x,
        // 	search_token: id.split("?", 1)[0]!="null" ?id.split("?", 1)[0]:null ,
        // 	is_offer: params.includes("offer"),
        // });
        // if (response.status === 200) {
        // 	let data = response.data;
        // 	let filtersNew = {};
        // 	if (x?.date_from&&x?.date_to) {
        // 		filtersNew = {
        // 			date_from: moment(x.date_from).format("YYYY-MM-DD"),
        // 			date_to: moment(x.date_to).format("YYYY-MM-DD"),
        // 			checkIn: moment(x.date_from).format("YYYY-MM-DD"),
        // 			checkOut: moment(x.date_to).format("YYYY-MM-DD"),
        // 		};
        // 	}
        // 	dispatch({ type: "setHotelFilters", payload: {hotelFilters:{ ...response?.data?.token_data?.search_payload }} });
        // 	dispatchGlobal({
        // 		type: "searchOffer",
        // 		payload: response.data.token_data,
        // 	});
        // 	// localStorage.setItem("filters", JSON.stringify({ ...test, ...x }));
        // 	dispatch({ type: "filter", payload: { ...filters, ...filtersNew } });
        // 	let rooms = data.rooms.map((res) => {
        // 		let listSeleted = [{ id: 0, name: 0 }];
        // 		[...Array(res.repeat.length).keys()].map((resa, index) => {
        // 			listSeleted.push({
        // 				id: index + 1,
        // 				name: index + 1,
        // 			});
        // 		});
        // 		return { ...res, listSeleted: listSeleted };
        // 	});
        // 	setHotel({ ...response.data, rooms: rooms });
        // 	let silders =
        // 		response.data.images && response.data.images.length > 0
        // 			? response.data.images.map((res, index) => {
        // 					return {
        // 						key: index + "uuid.v4(2)",
        // 						content: (
        // 							<img
        // 								className="bg-white"
        // 								src={res.url}
        // 								alt={index + 1}
        // 								onError={(e) => {
        // 									e.target.onerror = null;
        // 									e.target.src = noHotel;
        // 								}}
        // 							/>
        // 						),
        // 						onClick: () => setActive(index),
        // 					};
        // 			  })
        // 			: null;
        // 	setSilders(silders);
        // 	localStorage.setItem("HotelData", JSON.stringify(response.data));
        // 	setSelectedHotel({
        // 		...response.data,
        // 		hotel_code: response.data.hotel_code,
        // 		name: response.data.name,
        // 		address: response.data.address,
        // 		from: filters?.checkIn ? filters?.checkIn:x?.date_from,
        // 		to: filters?.checkOut ?filters?.checkIn:x?.date_from,
        // 		adult: filters?.adult,
        // 		child: filters?.child,
        // 		type: filters?.roomType,
        // 		rating: response.data.rating,
        // 	});
        // 	setImages(
        // 		response.data.images.map((img) => ({
        // 			original: `${img.imageUrl}`,
        // 			thumbnail: `${img.imageUrl}`,
        // 		}))
        // 	);
        // }
      } else if (filters != undefined) {
        const data = {
          search_token: id != "null" ? id : null,
          hotel_code: selectedHotelSearch?.hotel_code,
          date_from: moment(filters.checkIn).format("YYYY-MM-DD"),
          date_to: moment(filters.checkOut).format("YYYY-MM-DD"),
          nationality: "SA",
          room_type: filters.roomType,
          lang: locale,
          rooms_data: [
            {
              quantity: +filters.roomType,
              noOfAdults: +filters.adult,
            },
          ],
          ...hotelFilters,
        };

        if (+filters.child >= 1) {
          data.rooms_data[0].childrens = [];
          data.rooms_data[0].childrens.push({
            quantity: +filters.child,
            age: 11,
          });
        }
        if (hotel === null || locale) {
          let response;
          response = await fetchHotelBuilder(data);
          if (response.status === 200) {
            let data = response.data;

            let rooms = data.rooms.map((res) => {
              let listSeleted = [{ id: 0, name: 0 }];
              [...Array(res.repeat.length).keys()].map((resa, index) => {
                listSeleted.push({
                  id: index + 1,
                  name: index + 1,
                });
              });
              return { ...res, listSeleted: listSeleted };
            });

            setHotel({ ...response.data, rooms: rooms });

            let x =
              response.data.images && response.data.images.length > 0
                ? response.data.images.map((res, index) => {
                    return {
                      key: index + "uuid.v4(2)",
                      content: (
                        <img
                          className="bg-white"
                          src={res.url}
                          alt={index + 1}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = noHotel;
                          }}
                        />
                      ),
                      onClick: () => setActive(index),
                    };
                  })
                : null;
            setSilders(x);
            localStorage.setItem("HotelData", JSON.stringify(response.data));
            setSelectedHotel({
              ...response.data,
              hotel_code: response.data.hotel_code,
              name: response.data.name,
              address: response.data.address,
              from: filters?.checkIn,
              to: filters?.checkOut,
              adult: filters?.adult,
              child: filters?.child,
              type: filters?.roomType,
              rating: response.data.rating,
            });
            setImages(
              response.data.images.map((img) => ({
                original: `${img.imageUrl}`,
                thumbnail: `${img.imageUrl}`,
              }))
            );
          } else {
            // history.push("/market-view");
          }
        }
        return () => (isSubscribed = false);
      } else {
        const { date_from, date_to, nationality, rooms_data } = hotelFilters;
        const room_type = filters.roomType;
        const data = {
          search_token: id != "null" ? id : null,
          hotel_code: selectedHotelSearch.hotel_code,
          date_from,
          date_to,
          nationality,
          room_type,
          lang: locale,
          rooms_data,
        };
        let response;
        response = await fetchHotelBuilder(data);
        if (response.status === 200) {
          setHotel(response.data);
          setSelectedHotel({
            ...response.data,
            hotel_code: response.data.hotel_code,
            name: response.data.name,
            address: response.data.address,
            from: filters?.checkIn,
            to: filters?.checkOut,
            adult: filters?.adult,
            child: filters?.child,
            type: filters?.roomType,
            rating: response.data.rating,
          });
          setImages(
            response.data.images.map((img) => ({
              original: `${img.imageUrl}`,
              thumbnail: `${img.imageUrl}`,
            }))
          );
        } else {
          //   history.push("/market-view");
        }
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    let selectedcount = 0;
    selectedDataReq.map((res) => {
      selectedcount = selectedcount + res.room_rates[0].selected;
    });
    setCount(selectedcount);
  }, [selectedDataReq]);
  const [selectedData, setSelectedData] = useState({});

  console.log("hotel.rooms", hotel?.rooms);
  return (
    hotel !== null && (
      <div className="hotel-details">
        <div className="details-head">
          <PackageBreadCrumb />
        </div>
        <div className="details-info">
          <div className="title">
            <div className="text">
              <h6>
                {hotel?.name}
                <span className="rating">
                  {[...Array(+hotel?.rating).keys()].map((i) => (
                    <i key={i} className="fa fa-star" aria-hidden="true"></i>
                  ))}
                </span>
              </h6>
              <p>
                {" "}
                <LocationIcon /> {hotel?.address}
                {/* onClick={toggleMap} */}
                <a
                  href={`https://maps.google.com/?q=${hotel.latitude},${hotel.longitude}`}
                  target="_blank"
                  className="text-decor"
                  rel="noreferrer"
                >
                  <MapSMIcon /> View Location on map
                </a>
              </p>
            </div>
            {/* <div className="room-action">
              <ShareIcon />
              <button className="btn btn-primary">Select Room</button>
            </div> */}
          </div>
          <div className="slider-content">
            {Silders && Silders.length > 0 ? (
              <div
                style={{
                  height: "300px",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <Carousel
                  slides={Silders}
                  offsetRadius={2}
                  goToSlide={active}
                  offset={2}
                  width="30%"
                  margin="0 auto"
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className="details-overview">
          <h6>{webBuilder.overview}</h6>
          <p>{hotel.description}</p>
        </div>
        {hotel?.amenities?.length > 0 ? (
          <div className="details-facilities">
            <h6>Hotel Facilities</h6>
            <ul className="my-2">
              {hotel.amenities.map((facility, i) => {
                return (
                  <li key={i}>
                    <CheckIcon /> {facility}
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}

        <div className="details-rooms">
          <h6>{webBuilder.selectRooms}</h6>
          <div className="rooms-info">
            {hotel.rooms.map((room, i) => {
              if (
                room.roomData.roomTypes &&
                room.roomData.roomTypes.length > 0
              ) {
                return (
                  <>
                    <h3>
                      {room.roomData.roomText}
                      {/* Room1: Adults: 2, Children: 2, Children ages: 12,12 */}
                    </h3>

                    <Room
                      toggleCancellation={toggleCancellation}
                      selectedDataReq={selectedDataReq}
                      setSelectedDataReq={setSelectedDataReq}
                      selectedHotel={selectedHotel}
                      book={book}
                      key={i}
                      indexKey={i}
                      rooms={room}
                      image={hotel?.images}
                      selectedData={selectedData}
                      setSelectedData={setSelectedData}
                    />
                  </>
                );
              }
            })}
          </div>
        </div>
        <div className="d-flex justify-content-end book-btn pb-5">
          <button
            className="btn btn-primary "
            // onClick={() => history.push("/hotel/hotel-summary")}
            disabled={Count <= 0}
            onClick={() => {
              localStorage.setItem(
                "newCheckAvailability",
                JSON.stringify(selectedDataReq)
              );
              let x = {};
              name.forEach(function (value, key) {
                x = { ...x, [key]: value };
              });

              if (
                x.hasOwnProperty("search_token") &&
                x.hasOwnProperty("hotel_code")
              ) {
                localStorage.setItem(
                  "selectedHotelSearch",
                  JSON.stringify({
                    search_token_selected: x.search_token,
                    hotel_code: x.hotel_code,
                  })
                );
              }

              params.includes("token")
                ? history.push(`/hotel/hotel-summary?token=${x.token}`)
                : history.push(`/hotel/hotel-summary`);
            }}
          >
            {webBuilder.bookNow}
          </button>
        </div>
        <MapModel
          isOpen={mapIsOpen}
          toggle={toggleMap}
          latitude={hotel?.latitude}
          longitude={hotel?.longitude}
        />
        <CancellationModel
          isOpen={cancellationIsOpen}
          toggle={toggleCancellation}
          selectedData={selectedData}
          selectedHotel={selectedHotel}
        />
      </div>
    )
  );
}
