import React from "react";
import { Link } from 'react-router-dom';
import Locale from "translations";

export const ServiceCardDetails = (props) => {
  const { onClick, image, name, product_uuid, itemType, price, currency } = props
  const { webBuilder } = Locale;



  return (
    <Link to={product_uuid ? `/${itemType}/${product_uuid}` : "/"}>
      <article className="service-card-details pointer" style={{ backgroundImage: `url(${image})` }}>
        <div onClick={onClick} className='service-card-details-name'>
          {name}
          {price ?
            <div className="start-price">
              <span>{webBuilder.from}</span>
              <span className="amount">{price} {currency || ""}</span>
            </div>
            :
            null
          }
        </div>
      </article>
    </Link>
  );
};
