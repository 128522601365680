
import { useWebBuilderState } from "context/webBuilder";
import React, { useEffect } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import Locale from "translations";

function HeroHeader({ editHeader }) {
  const { webBuilder } = Locale;
  const { hero, ourServices } = useWebBuilderState();

  const location = useLocation();
  const preview = !location.pathname.includes('edit');

  const header = hero?.header;
  const serviecs = ourServices?.services;
  const layoutType = header?.layout?.layoutType;
  const hasButton = header?.layout?.hasButton;
  const hasLogo = header?.layout?.hasLogo;
  const headerBg = header?.layout?.background === "white";

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };


  const history = useHistory();
  const params = useParams();

  // scroll to location from view package page
  useEffect(() => {
    if (location.state) {
      setTimeout(() => {
        scrollToSection(location.state);
      }, 500);
    }
  }, [])

  const nav = (
    <nav>
      <ul>
        <li onClick={() => {
          history.push(`/v1`)
        }} className={headerBg ? "text-secondary-color" : ""}>{webBuilder.home}</li>
        {serviecs && serviecs.length > 0 && serviecs?.find(service => service?.visible) ? <li className={headerBg ? "text-secondary-color" : ""}>
          <UncontrolledDropdown>
            <DropdownToggle
              caret
              className={`service-dropdown ${headerBg ? "" : ""
                }`}
              style={{ gap: "12px", padding: "1px" }}
            >
              Services
            </DropdownToggle>

            <DropdownMenu right>
              {serviecs &&
                serviecs.map((service) =>
                  service.visible ? (
                    <DropdownItem
                      key={service.type}
                      onClick={() => {
                        if (params.id) {
                          history.push({ pathname: `/v1`, state: service.id })
                        } else {
                          scrollToSection(service.id)
                        }
                      }}
                    >
                      {webBuilder[service.type] || service.type}
                    </DropdownItem>
                  ) : null
                )}
            </DropdownMenu>
          </UncontrolledDropdown>
        </li> : null}
        <li
          onClick={() => {
            if (params.id) {
              history.push({ pathname: `/v1`, state: "about-us-section" })
            } else {
              scrollToSection("about-us-section")
            }
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.aboutus}
        </li>
      </ul>
    </nav>
  );

  const logo = header?.logo ? <img src={header?.logo} alt="Logo" /> : null;


  const clickContactButton = () => {
    if (preview) {
      scrollToSection(header?.contactUs?.id);
      return;
    }
  };


  const contactButton = hasButton && (
    <button className="contact-button"
      onClick={() => {
        // check if we inside view package page when click scroll back to preview page the scroll using useeffect
        if (params.id) {
          history.push({ pathname: `/v1`, state: header?.contactUs?.id })
        } else {
          clickContactButton()
        }
      }}
    >
      {header?.contactUs?.name}
    </button>
  );

  return (
    <>
      <div
        className={`web-builder-hero-header ${headerBg ? "bg-white" : ""}`}
        onClick={editHeader}
      >
        {layoutType === 1 || layoutType === 2 ? (
          <header className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}>
            {hasLogo && (
              <div className="logo">
                {header?.layout?.hasLogo ? logo : null}
              </div>
            )}
            {nav}
            <div className="d-flex gap-10">
              <LanguageSwitcher show={false} />
              <CurrencySwitcher show={false} />
              {contactButton}
            </div>
          </header>
        ) : (
          <header className={`${+layoutType === 4 ? "flex-row-reverse " : ""}`}>
            <div className="d-flex gap-10">
              <LanguageSwitcher show={false} />
              <CurrencySwitcher show={false} />
              {contactButton}
            </div>

            {hasLogo && (
              <div className="logo">
                {header?.layout?.hasLogo ? logo : null}
              </div>
            )}
            {nav}
          </header>
        )}


      </div>
    </>
  );
}

export default HeroHeader;
