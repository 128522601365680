import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { useSBSState } from "context/global";

function FAQs({ details }) {
  const { locale } = useSBSState();
  const { id, withImage, body, title, faqs, image } = details;
  const [collapsedItems, setCollapsedItems] = useState([]);

  return (

    <div className="web-builder-faqs-container container mt-3" id={id}>
      <div className={` ${withImage ? "faq-with-image" : "faq-column-view"}`}>
        <div className="custom-container-top-section">
          <ServicesContent
            header={title}
            body={withImage ? "  " : body}
          />
        </div>
        <div className={`faq-detials`}>
          <div className="faq-questions-container">
            {faqs ?
              faqs.map((que, index) => {
                const isOpen = collapsedItems.includes(index);
                const openCollapse = () => {
                  const updated = [...collapsedItems, index];
                  setCollapsedItems(updated);
                };
                const closeCollapse = () =>
                  setCollapsedItems(
                    collapsedItems.filter((i) => i !== index)
                  );

                return (
                  <div className="faqs-question-wrapper" key={que.id}>
                    <div className="faqs-container-question">
                      <span> {que.question?.[locale]} </span>
                      {isOpen ?
                        <button className="bg-transparent border-0 faq-btn" onClick={closeCollapse}>
                          <MinusIcon />
                        </button>
                        :
                        <button className="bg-transparent border-0 faq-btn" onClick={openCollapse}>
                          <PlusIcon />
                        </button>
                      }
                    </div>

                    <Collapse isOpen={isOpen}>
                      <p className="faqs-container-body">
                        {que.body?.[locale]}
                      </p>
                    </Collapse>
                  </div>
                );
              })
              :
              null
            }
          </div>

          {withImage ? <>{<img src={image} className="faq-image" alt="Faq-section" />}</> : null}

        </div>
      </div>
    </div>
  );
}

export default FAQs;
