import { useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location.svg";
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/callcalling.svg";
import { ReactComponent as FacebookIcon } from "assets/images/webBuilder/Facebook.svg";
import { ReactComponent as TwitterIcon } from "assets/images/webBuilder/Twitter.svg";
import { ReactComponent as YouTubeIcon } from "assets/images/webBuilder/youtube.svg";
import { ReactComponent as LinkedInIcon } from "assets/images/webBuilder/linkedin.svg";
import { ReactComponent as InstagramIcon } from "assets/images/webBuilder/instagram.svg";


export default function Footer({ focusContainer, handleFocus }) {
  const { footer: footerDetails } = useWebBuilderState();
  const type = 'footer';

  const socialMediaIcons = {
    "facebook": <FacebookIcon />,
    "twitter": <TwitterIcon />,
    "youtube": <YouTubeIcon />,
    "instagram": <InstagramIcon />,
    "linkedin": <LinkedInIcon />,
  }

  return (
    <footer className='footer-container'>
      <div className="container">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex align-items-center">
            <span className='mx-1'><LocationIcon /></span>
            <p className={"text-white"}>
              {footerDetails?.address}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between mt-3">
            <span className='mx-1'><PhoneIcon /></span>
            {footerDetails?.phoneNumbers?.map((phone, index) => {
              return (
                <div className='d-flex mx-2 justify-content-between' key={index}>
                  <p className="text-white">{phone}</p>
                </div>
              )
            })}
          </div>

          {/* social icons */}
          <div onClick={() => handleFocus(type)} className='mt-4'>
            <div className="d-flex align-items-center justify-content-between py-2">
              {footerDetails?.links?.map(link => {
                if (link?.visible) {
                  return (
                    <div key={link?.id} className='mx-2'>
                      <a href={link?.url} target='_blank' rel="noreferrer">
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  )
                } else {
                  return null
                }

              })}
            </div>
          </div>

          <div className="footer-copyrights">
            <p>{footerDetails?.allRights}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}
