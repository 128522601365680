
export function handleSearch(countryId, proudctType, content = [], pagesData) {

  let serviceType = proudctType;

  let isProductTypePackage = proudctType?.id === 2 || proudctType?.id === 3 || proudctType?.id === 4 || proudctType?.id === 1;

  let pagesDataContent = [];
  // get all packages , hotels and tours from home and custom pages content
  for (let i = 0; i < pagesData?.length; i++) {
    let pageContent = pagesData[i]?.content;
    if (pageContent) {
      for (let j = 0; j < pageContent.length; j++) {
        if (pageContent[j].items.length > 0) {
          pagesDataContent.push(pageContent[j])
        }
      }
    }
  }

  let allContent = [...content, ...pagesDataContent]
  let searchResults = [];
  if (isProductTypePackage) {
    searchResults = allContent?.filter(item => item?.type?.toLowerCase() === "packages")
    searchResults = searchResults.map(res => res?.items?.filter(item => item?.product_uuid && item?.product_type?.id === serviceType?.id && item?.country_id === countryId)).flat(1)
  } else {
    searchResults = allContent?.filter(item => item?.type?.toLowerCase() === proudctType?.name?.toLowerCase());
    searchResults = searchResults.map(res => res?.items?.filter(item => item?.product_uuid && item?.country_id === countryId)).flat(1)
  }

  searchResults = [...new Map(searchResults.map(item => [item["product_uuid"], item])).values()];
  return searchResults
}