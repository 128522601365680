import React, { useEffect } from "react";
import PackageBreadCrumb from "./breadCrumb";
import SeachResult from "./searchResult";
import { useWebBuilderDispatch, useWebBuilderState } from "context/webBuilder";
import { getPageBuilderByCompany } from "services/webBuilder";
import { changeBorder, changeColorTheme, changeFont } from "../helpers/styles";

export default function PackageSearch() {
  const { packagesResult, style } = useWebBuilderState();
  const dispatch = useWebBuilderDispatch();

  const updateStyle = ({ name, value }) => {
    dispatch({
      type: "updateStyle",
      payload: {
        name,
        value,
      },
    });
  };

  const getPageDetails = async () => {
    let res;

    res = await getPageBuilderByCompany(process.env.REACT_APP_WebsiteName);

    if (res.status === 200) {
      let pageBuilderData = res.data.page_builder_config;
      dispatch({
        type: "setInitialData",
        payload: pageBuilderData,
      });
      const apiStyle = pageBuilderData?.style;
      changeColorTheme(apiStyle?.color, updateStyle);
      changeBorder(apiStyle?.rounded, updateStyle);
      changeFont(apiStyle?.font, updateStyle);
    }
  };

  useEffect(() => {
    if (!packagesResult) {
      getPageDetails();
    } else {
      changeColorTheme(style?.color, updateStyle);
      changeBorder(style?.rounded, updateStyle);
      changeFont(style?.font, updateStyle);
    }
    return () => {};
  }, []);

  return (
    <div
      className="public-page-search web-builder-preview"
      id="main-web-builder"
    >
      {/* <HeroHeader /> */}
      <PackageBreadCrumb />
      <SeachResult />
    </div>
  );
}
